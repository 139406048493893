import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import Layout from "../layout/Layout";
import _ from "lodash";
import { Helmet } from "react-helmet";
import "../Shelf/style.scss";
import { Link } from "react-router-dom";
import Trust1 from "../../assets/images/Trust-Badge-1.png";
import Trust2 from "../../assets/images/Trust-Badge-2.png";
import Trust3 from "../../assets/images/Trust-Badge-3.png";
import Trust4 from "../../assets/images/Trust-Badge-4.png";
import Trust5 from "../../assets/images/Trust-Badge-5.png";
import Trust6 from "../../assets/images/Trust-Badge-6.png";
import ThingsToKnow from "../../assets/images/1-circle-img-medical101.png";

class MedicalMarijuana extends Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Medical Marijuana 101</title>
        </Helmet>

        <div className="container-fluid" style={{ marginTop: 90 }}>
          <div className="app-wrapper">
            <div className="wovenwire-cta-container">
              <div className="container">
                <center>
                  <div>
                    <h1
                      style={{
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        fontSize: 48,
                        textTransform: "uppercase",
                      }}
                    >
                      Medical Marijuana 101
                    </h1>
                  </div>
                </center>
              </div>
            </div>

            <div className="join-cta-container">
              <div className="container" style={{ backgroundColor: "#" }}>
                <center>
                  <div>
                    <h2
                      style={{
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        fontSize: 28,
                        textTransform: "uppercase",
                      }}
                    >
                      Learn More About Medical Marijuana In Oklahoma
                    </h2>
                    <p>
                      With the medical marijuana industry thriving, there seems
                      to be no shortage of medical dispensaries now offering
                      medical cannabis products for patients. However,
                      navigating the medical marijuana world can be overwhelming
                      for anyone looking for a high-quality medical marijuana
                      dispensary in Oklahoma. CriticaPro is a medical marijuana
                      dispensary locator. From finding an OMMA certified doctor
                      to a medical dispensary, CriticaPro can help you.
                    </p>
                  </div>
                </center>
              </div>
            </div>

            <div className="cta-container">
              <div>
                <img src={Trust1} style={{ width: "100%" }} />
              </div>
              <div>
                <img src={Trust2} style={{ width: "100%" }} />
              </div>
              <div>
                <img src={Trust3} style={{ width: "100%" }} />
              </div>
              <div>
                <img src={Trust4} style={{ width: "100%" }} />
              </div>
              <div>
                <img src={Trust5} style={{ width: "100%" }} />
              </div>
              <div>
                <img src={Trust6} style={{ width: "100%" }} />
              </div>
            </div>

            <div className="cta-container">
              <div className="col-md-6">
                <img src={ThingsToKnow} style={{ width: "100%" }} />
              </div>
              <div className="col-md-6">
                <h3>Things to Know About Medical Marijuana: </h3>
                <p>Gettiing started</p>
                <p>The science behind medical marijuana</p>
                <p>Therapeutic and medical benefits</p>
                <p>The forms of medication available</p>
                <p>Become a patient</p>
              </div>
            </div>

            <div className="cta-container">
              <div className="col-md-6">
                <h3>Getting Started</h3>
                <p>
                  Research suggests medical cannabis is valuable in the
                  treatment of a number of ailments, including:
                </p>
                <p>•Neuropathic pain</p>
                <p>•Nausea</p>
                <p>•Glaucoma</p>
                <p>•Movement disorders</p>
                <p>•Spasticity</p>
                <p>•Cancer</p>
                <p>•And more...</p>
                <p>
                  •If you have a medical condition that medical marijuana can
                  help, CriticaPro can connect you to an OMMA (Oklahoma Medical
                  Marijuana Authority) compliant doctor.{" "}
                </p>
              </div>
              <div className="col-md-6">
                <img src={ThingsToKnow} style={{ width: "100%" }} />
              </div>
            </div>

            <div className="join-cta-container">
              <div className="container" style={{ backgroundColor: "#" }}>
                <center>
                  <div>
                    <h2
                      style={{
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        fontSize: 28,
                        textTransform: "uppercase",
                      }}
                    >
                      The Science Behind Medical Marijuana
                    </h2>
                    <p>
                      Marijuana is the name of the dried buds and leaves of
                      Cannabis sativa plant varieties, which grow wild in warm,
                      dry climates worldwide. Marijuana is commonly known as
                      weed, pot, cannabis, hemp, ganja, and dozens of other
                      names. In the past couple of decades, scientists have
                      begun to understand the specific medical benefits of the
                      two main ingredients in medical marijuana: THC and CBD.
                    </p>
                    <p>
                      It is important to understand the difference between CBD
                      and THC and how the ratio of the two influences the
                      effects medical marijuana has on your body.
                      Delta-9-tetrahydrocannabinol, commonly known as THC, and
                      cannabidiol, more commonly known as CBD, are the two main
                      types of cannabinoids that have been studied. THC is a
                      psychoactive cannabinoid, while CBD is non-psychoactive
                      and counteracts THC’s psychoactivity, working
                      synergistically to treat a patient. Make sure the products
                      you buy are lab tested; this is important for your safety
                      and health. Always read instructions on packaged products,
                      and know what you’re purchasing.
                    </p>
                  </div>
                </center>
              </div>
            </div>

            <div className="cta-container">
              <div className="col-md-6">
                <img src={ThingsToKnow} style={{ width: "100%" }} />
              </div>
              <div className="col-md-6">
                <p>Therapeutic and Medical Benefits of Medical Marijuana </p>
                <p>
                  Worldwide, cultures have been using cannabis seeds, flowers,
                  and buds for medical purposes. Ancient texts in both Chinese
                  and Indian cultures speak of the herb’s therapeutic effects.
                  Some of the benefits include relieving pain, boosting energy,
                  and reducing anxiety.
                </p>
                <p>
                  Cannabis can be found in various forms. The health benefits of
                  cannabis are ever-growing. Cannabis contains CBD, a chemical
                  that impacts the brain, making it function better without the
                  high, along with THC’s pain-relieving properties. Both
                  substances can be extracted and enhanced for use through short
                  path distillation.
                </p>
                <Link to="/blog">
                  <p>
                    Learn more about the uses and benefits of medical marijuana
                    by visiting the CriticaPro blog.
                  </p>
                </Link>
              </div>
            </div>

            <div className="cta-container">
              <div className="col-md-6">
                <p>Available Forms of Medical Marijuana</p>
                <p>
                  The medical marijuana industry has evolved over the years to
                  provide patients with a variety of medicinal products. You
                  don’t have to smoke your medicine anymore. Medical marijuana
                  is available in many forms, including:
                </p>
                <p>
                  Medical Marijuana Flower – Also known as a strain, this refers
                  to the unprocessed dry flower you can buy at the medical
                  dispensary.
                </p>
                <p>
                  Pre-rolls – Joints made from different medical cannabis
                  strains.
                </p>
                <p>
                  Medical Cannabis Edibles – All foods and drinks made with
                  medical marijuana.
                </p>
                <p>
                  Medical Marijuana Concentrates – Available in cartridges for
                  vape pens plus oils, waxes, kief, hash, and rosin.
                </p>
                <p>
                  Medical Cannabis Capsules – Medical cannabis in pill form;
                  these are often high in CBD.
                </p>
                <p>
                  •Vape Pen – Allows you to ingest medical marijuana from a
                  discreet pen.
                </p>
                <p>
                  Sublingual Medical Cannabis - Available as tablets, sprays,
                  tinctures, and strips to dissolve in your mouth or under your
                  tongue and can quickly take effect.
                </p>
                <p>
                  Topicals – Oils, creams, lotions, balms, and salves—all of
                  which provide localized relief.
                </p>
                <p>Locate a medical marijuana dispensary in Oklahoma </p>
                <Link to="/dispensaries">
                  <p>to find your favorite forms of medicine.</p>
                </Link>
              </div>
              <div className="col-md-6">
                <img src={ThingsToKnow} style={{ width: "100%" }} />
              </div>
            </div>

            <div className="cta-container">
              <div className="col-md-6">
                <img src={ThingsToKnow} style={{ width: "100%" }} />
              </div>
              <div className="col-md-6">
                <p>
                  Becoming a medical marijuana patient in Oklahoma is a
                  relatively simple process. First and foremost, you need to
                  locate and schedule an appointment with an OMMA doctor. Bring
                  the OMMA’s Physician Recommendation Form and be prepared to
                  discuss why you’re looking to get a medical marijuana card.
                </p>
                <p>
                  Medical marijuana patients often experience conditions such
                  as:
                </p>
                <p>Anxiety and depression</p>
                <p>Insomnia</p>
                <p>Migraines</p>
                <p>Nausea</p>
                <p>Inflammation</p>
                <p>
                  Your OMMA doctor may recommend medical cannabis for any
                  condition they believe will be improved through medical
                  marijuana administration. Once you receive your physician’s
                  signature and pay the application fee, you can apply for your
                  Oklahoma Medical Marijuana card. You should expect a response
                  within two weeks of applying.
                </p>
              </div>
            </div>

            <div className="join-cta-container">
              <div className="container" style={{ backgroundColor: "#" }}>
                <center>
                  <div>
                    <h2
                      style={{
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        fontSize: 28,
                        textTransform: "uppercase",
                      }}
                    >
                      When you have been approved and receive your MMJ card,
                      visit one of Oklahoma's medical marijuana dispensaries.
                    </h2>
                    <p>Use CriticaPro To Locate a MMJ Dispensary Near You</p>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  authUser: state.sessionState.authUser,
});

export default compose(connect(mapStateToProps, {}))(MedicalMarijuana);
