import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/critica_logo_color.svg";
import * as routes from "../../constants/routes";

const Footer = () => {
  return (
    <div className="footer">
      <div style={{ marginLeft: 24, paddingBottom: 12 }}>
        <div style={{ marginTop: 20 }}>
          <div style={{ marginBottom: 16, paddingTop: 12 }}>
            <img
              src={logo}
              className="app-logo-footer"
              alt="critica pro logo"
              draggable="false"
            />
          </div>
        </div>
        <div>
          <p>
            © 2023 CriticaPro All Rights Reserved &middot;{" "}
            <Link to={routes.TERMS} style={{ color: "rgb(4 253 118)" }}>
              Terms of Service
            </Link>{" "}
            |{" "}
            <Link to={routes.PRIVACY} style={{ color: "rgb(4 253 118)" }}>
              Privacy Policy
            </Link>{" "}
            |{" "}
            <Link to={routes.CONTACT} style={{ color: "rgb(4 253 118)" }}>
              Contact Us
            </Link>{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
