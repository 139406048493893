import database from "../firebase/firebase";
import firebase from "firebase/compat/app";

export const SET_JOBS = "SET_JOBS";
export const ADD_JOB = "ADD_JOB";

export const setJobs = (jobs) => ({
  type: SET_JOBS,
  jobs,
});

export const startSetJobs = (uid) => {
  return async (dispatch) => {
    try {
      const snapshot = await firebase
        .firestore()
        .collection("jobDB")
        .doc(`${uid}`)
        .collection("service-address")
        .get();
      
      let jobs = [];

      snapshot.forEach((doc) => {
        let reviewsData = doc.data();
        jobs.push(reviewsData);
      });

      dispatch(setJobs(jobs));
    } catch (error) {
      console.log(error, "error");
    }
  };
};

// ADD_JOB
export const addJob = (slug, jobBody) => ({
  type: ADD_JOB,
  slug,
  jobBody,
});

export const startAddJob = (uid, reviewData = {}) => {
  return (dispatch) => {
    const db = firebase.firestore();

    const newReviewKey = firebase
      .database()
      .ref(`reviews/${uid}`)
      .child("review")
      .push().key;

    const {
      review_body = "",
      companyUID = "",
      reason = "",
      createdAt = 0,
      likes = 0,
      reviewId = newReviewKey,
      photoURL = firebase.auth().currentUser.photoURL,
      uid = firebase.auth().currentUser.uid,
      displayName = firebase.auth().currentUser.displayName,
      company = "",
      rating = 0,
      id = "",
      slug,
    } = reviewData;

    const jobBody = {
      companyUID,
      company,
      displayName,
      uid,
      reason,
      slug,
      photoURL,
      review_body,
      createdAt,
      likes,
      reviewId,
      rating,
      id,
    };

    db.collection("jobDB")
      .doc(`${uid}`)
      .collection("service-reviews")
      .doc(`${reviewId}`)
      .set(jobBody, { merge: true });

    db.collection("usersDB")
      .doc(`${uid}`)
      .collection("reviews")
      .doc(`${reviewId}`)
      .set(jobBody, { merge: true });

    let updates = {};
    updates[`/jobs/${uid}/${newReviewKey}`] = jobBody;
    // updates[`/users/${uid}/reviews/${newReviewKey}`] = jobBody;

    return database
      .ref()
      .update(updates)
      .then((ref) => {
        dispatch(
          addJob({
            // id: ref.key,
            ...jobBody,
          })
        );
      });
  };
};
