import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as routes from "../../constants/routes";
import { auth } from "../../firebase";
import logo from "../../assets/images/critica_logo_color_dark.svg";

const styles = {
  sidebar: {
    width: 256,
    height: "100%",
    zIndex: 100,
  },
  sidebarLink: {
    // boxShadow: "0 0px 4px 0 #bbbbbb50, 0 3px 9px 0 rgba(17, 27, 106, 0.064)",
    display: "block",
    color: "#757575",
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
    padding: "16px 0px",
    textDecoration: "none",
    paddingLeft: "18px",
    marginBottom: 12
  },
  divider: {
    margin: "8px 0",
    height: 2,
    // backgroundColor: "#d7d7d7",
  },
  content: {
    // padding: "16px",
    height: "100%",
    // backgroundColor: "white"
  },
};

const SidebarContent = (props) => {
  const style = props.style
    ? { ...styles.sidebar, ...props.style }
    : styles.sidebar;

  return (
    <>
      <div style={styles.content}>

        <div style={{marginBottom: 24}}>
          <a href="/">
          <img
            src={logo}
            style={{width: 200, }}
            // className="app-logo-header"
            alt="critica pro logo"
          />
          </a>
        </div>

        {props.authUser ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              <img
                src={props.user.photoURL}
                className="mobile-nav-profile-img"
                alt="user avatar"
              />
            </div>
            <div>
              <span
                style={{
                  padding: "16px 0px",
                  textDecoration: "none",
                  paddingLeft: "8px",
                  fontWeight: 'bold'
                }}
              >
                {props.user.company
                  ? props.user.company
                  : props.user.displayName}
              </span>
            </div>
          </div>
        ) : (
          <div></div>
        )}

      {props.authUser ? (
        <>
        <div style={styles.divider} />
        <span style={styles.sidebarLink}>
          <Link to={routes.LANDING} style={{ fontWeight: "bold" }}>
            {" "}
            <i className="fas fa-bars" style={{ marginRight: 8 }}></i> Dashboard
          </Link>
        </span>
        </>
        ) : (
          <div></div>
        )}
      
      {/* {props.authUser ? (
        <>
        <div style={styles.divider} />
        <span style={styles.sidebarLink}>
          <Link to={routes.DASHBOARD} style={{ fontWeight: "bold" }}>
            {" "}
            <i className="fas fa-wrench" style={{ marginRight: 8 }}></i> My jobs
          </Link>
        </span>
        </>
        ) : (
          <div></div>
        )} */}
      
      {props.authUser ? (
        <>
        <div style={styles.divider} />
        <span style={styles.sidebarLink}>
          <Link to={routes.REVIEWS} style={{ fontWeight: "bold" }}>
            {" "}
            <i className="fas fa-edit" style={{ marginRight: 8 }}></i> My reviews
          </Link>
        </span>
        </>
        ) : (
          <div></div>
        )}
      
      {props.authUser ? (
        <></>
        ) : (
          <Link to={routes.SIGN_UP} style={{ fontWeight: "bold",  }}>
            <span style={styles.sidebarLink}>
            {" "}
            <i className="fas fa-key" style={{ marginRight: 8 }}></i> Sign up
            </span>
          </Link>
        )}
      
      {props.authUser ? (
        <></>
        ) : (
          <Link to={routes.SIGN_IN} style={{ fontWeight: "bold"}}>
            <span style={styles.sidebarLink}>
            {" "}
            <i className="fas fa-key" style={{ marginRight: 8 }}></i> Login
            </span>
          </Link>
        )}





        {/* {props.authUser ? (
          <div>
            <div style={styles.divider} />
            <span style={styles.sidebarLink}>
              <Link to={routes.JOBS} style={{ fontWeight: "bold" }}>
                <i className="fas fa-map" style={{ marginRight: 8 }}></i>{" "}
                Service Map
              </Link>
            </span>
            <div style={styles.divider} />
            <span style={styles.sidebarLink}>
              <Link onClick={auth.doSignOut} style={{ fontWeight: "bold" }}>
                Sign Out
              </Link>
            </span>
          </div>
        ) : (
          <div></div>
        )} */}
        {props.authUser ? (
          <div>
            <div style={styles.divider} />
            <span style={styles.sidebarLink}>
              <Link to={'/'} style={{ fontWeight: "bold" }}>
                <i className="fas fa-cogs" style={{ marginRight: 8 }}></i>{" "}
                Account settings
              </Link>
            </span>
            <div style={styles.divider} />
            <span style={styles.sidebarLink}>
              <Link onClick={auth.doSignOut} style={{ fontWeight: "bold" }}>
                Sign out
              </Link>
            </span>
          </div>
        ) : (
          <div></div>
        )}

        <h3 style={{ fontWeight: "bold" }}>Support</h3>
        <span style={styles.sidebarLink}>
          <a href="mailto:support@criticapro.com">
            <p style={{ fontWeight: "bold" }}>Email</p>
          </a>
        </span>
        
        <div style={{ marginTop: 8 }}>
          <p>
            © 2023 CriticaPro All Rights Reserved &middot;{" "}
            <br/>
            <a href='https://www.criticapro.com/legal/terms' style={{ color: "#00aa4e" }} target="blank">
              Terms of Service
            </a>
            {" "}|{" "}
            <a href='https://www.criticapro.com/legal/privacy' style={{ color: "#00aa4e" }} target="blank">
              Privacy Policy
            </a>
          </p>
          
        </div>
      </div>
    </>
  );
};

SidebarContent.propTypes = {
  style: PropTypes.object,
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  userProps: state.userProps,
  user: state.user,
});

export default connect(mapStateToProps, undefined)(SidebarContent);
