// Reviews Reducer
import { SET_REVIEWS_LOCATIONS } from "../actions/reviews_locations";

const reviewsReducerDefaultState = [];

export default (state = reviewsReducerDefaultState, action) => {
  switch (action.type) {
    case SET_REVIEWS_LOCATIONS:
      return action.reviews_locations;
    default:
      return state;
  }
};
