import React from 'react';
import { connect } from 'react-redux';
import BusinessForm from './BusinessForm';
import { startAddBusiness } from '../../actions/business';
import Header from '../layout/Header';
import Navigation from '../layout/Navigation';

export class AddBusinessView extends React.Component {
  onSubmit = (business) => {
    this.props.startAddBusiness(business);
    this.props.history.push('/');
  };
  render() {
    return (
      <div>
        <Navigation/>
        <div className="container" style={{ marginTop: 120 }}>
        
          <div className="content-container">
            <BusinessForm
              onSubmit={this.onSubmit}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startAddBusiness: (business) => dispatch(startAddBusiness(business))
});

export default connect(undefined, mapDispatchToProps)(AddBusinessView);
