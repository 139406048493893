import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import withAuthorization from "../session/withAuthorization";
import firebase from "firebase/compat/app";
import { startUserProps } from "../../actions/user";
import { fetchRecentNews } from "../../actions/recent_news";
import { Grid, Col } from "react-bootstrap";
import _ from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";
import Layout from "../layout/Layout";
import no_order_img from "../../assets/images/no_order_img@2x.png";
import LocalMap from "./LocalMap";
import LocalDirectory from "./LocalDirectory";
import SidebarContentAuth from "./SidebarContentAuth";
import DealsMenu from "./DealsMenu";
import LocationSearchInput from "./LocationSearchInput";
const Dashboard = () => {
  return (
    <Layout>
      <div style={{ display: "flex", flex: 1 }}>
        <LocationSearchInput />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  products: state.shelf.products,
  user: state.user,
  authUser: state.sessionState.authUser,
  profile: state.profile,
  recent_news: state.recent_news,
});

// const mapDispatchToProps = (dispatch) => ({
//   // startUserProps: () => dispatch(startUserProps()),
//   // fetchRecentNews: () => dispatch(fetchRecentNews()),
// });

// const authCondition = (authUser) => !!authUser;

export default connect(mapStateToProps, undefined)(Dashboard);
