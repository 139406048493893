import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import ListIndexFilters from "./ListIndexFilters";
import { startSetJobs } from "../../actions/jobs";
import itemSelector from "./../../selectors/selector";
import GoogleMap from "google-map-react";
import _ from "lodash";
import { Col } from "react-bootstrap";
import MapSmallCard from "./MapSmallCard";
import LoadingPage from "../utilities/LoadingPage";
import Navigation from "../layout/Navigation";
import Footer from "../layout/Footer";
import { Helmet } from "react-helmet";
import defaultPhotoURL from "../../assets/images/default_avatar.jpg";
const K_HOVER_DISTANCE = 100 / 2;

const HeaderTags = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>My Jobs - CriticaPro</title>
    <link rel="canonical" href="https://criticapro.com/blog" />
  </Helmet>
);

var cities = [
  { city_name: "Select city" },
  { city_name: "Admore" },
  { city_name: "Arapaho" },
  { city_name: "Arcadia" },
  { city_name: "Arkadelphia" },
  { city_name: "Arkoma" },
  { city_name: "Bartlesville" },
  { city_name: "Bentonville" },
  { city_name: "Bethany" },
  { city_name: "Blanchard" },
  { city_name: "Broken Arrow" },
  { city_name: "Broken Bow" },
  { city_name: "Calera" },
  { city_name: "Claremore" },
  { city_name: "Carnegie" },
  { city_name: "Cartwright" },
  { city_name: "Chandler" },
  { city_name: "Chickasha" },
  { city_name: "Noble" },
  { city_name: "Choctaw" },
  { city_name: "Claremore" },
  { city_name: "Clarksville" },
  { city_name: "Cookson" },
  { city_name: "Cordell" },
  { city_name: "Coyle" },
  { city_name: "Del City" },
  { city_name: "Duncan" },
  { city_name: "Edmond" },
  { city_name: "El Reno" },
  { city_name: "Elk city" },
  { city_name: "Enid" },
  { city_name: "Goldsby" },
  { city_name: "Grove" },
  { city_name: "Harrah" },
  { city_name: "Henryetta" },
  { city_name: "Holdenville" },
  { city_name: "Hot Springs" },
  { city_name: "Hugo" },
  { city_name: "Hydro" },
  { city_name: "Jenks" },
  { city_name: "Lawton" },
  { city_name: "Lexington" },
  { city_name: "Lone Grove" },
  { city_name: "Marlow" },
  { city_name: "McAlester" },
  { city_name: "Mead" },
  { city_name: "Meeker" },
  { city_name: "Midwest City" },
  { city_name: "Mustang" },
  { city_name: "Minco" },
  { city_name: "Monkey island" },
  { city_name: "Moore" },
  { city_name: "Muskogee" },
  { city_name: "Newcastle" },
  { city_name: "Norman" },
  { city_name: "Nicoma Park" },
  { city_name: "Oklahoma City", center: { lat: 35.4825666, lng: -97.6196207 } },
  { city_name: "Okmulgee" },
  { city_name: "Owasso" },
  { city_name: "Pauls Valley" },
  { city_name: "Pawhuska" },
  { city_name: "Piedmont" },
  { city_name: "Poteau" },
  { city_name: "Prague" },
  { city_name: "Pryor" },
  { city_name: "Purcell" },
  { city_name: "Roland" },
  { city_name: "Russellville" },
  { city_name: "Sallisaw" },
  { city_name: "Sand Springs" },
  { city_name: "Sapulpa" },
  { city_name: "Sayre" },
  { city_name: "Seminole" },
  { city_name: "Shawnee" },
  { city_name: "Skiatook" },
  { city_name: "Slaughterville" },
  { city_name: "Stillwater" },
  { city_name: "Tahlequah" },
  { city_name: "Tecumseh" },
  { city_name: "The Village" },
  { city_name: "Tulsa" },
  { city_name: "Watts" },
  { city_name: "Warr Acres" },
  { city_name: "Weatherford" },
  { city_name: "Wellston" },
  { city_name: "Wewoka" },
  { city_name: "Woodward" },
  { city_name: "Yukon" },
];

class ExploreJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: {},
      displayName: "",
      cities: cities,
      city_name: "",
      user_search: null,
      zoomToMarkers: null,
      zoom: null,
      center: null,
      markers: [],
    };
  }

  componentDidMount() {
    this.getLocation();
    const { uid } = this.props.user;

    this.props.startSetJobs(uid);
    console.log("CALLING JOBS::: ");
  }

  static defaultProps = {
    zoom: 12,
  };

  _onBoundsChange = (center, zoom, bounds, marginBounds) => {
    if (this.props.onBoundsChange) {
      this.props.onBoundsChange({ center, zoom, bounds, marginBounds });
    } else {
      this._onChange(center);
    }
  };

  _onChange({ center, zoom, bounds, ...other }) {
    this.setState({ zoom: zoom });
  }

  onSearchInputChange = (e) => {
    const displayName = e.target.value;
    this.setState(() => ({ displayName }));
  };

  onStateChange = (e, center) => {
    const city_name = e.target.value;
    this.setState(() => ({ city_name, center }));
    // this.props.startSetJobs(city_name);
  };

  searchDispensaries = () => {
    const cityName = this.state.city_name;
    // this.props.startSetJobs(cityName);
  };

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.setState({ center: pos });
      });
    } else {
      this.setState({ err: "Geolocation is not supported by this browser." });
    }
  }

  star_rating = (rating) => {
    switch (rating) {
      case 1:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#f44336" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
          </span>
        );
      case 2:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#f44336" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#f44336" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
          </span>
        );
      case 3:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#FBC02D" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#FBC02D" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#FBC02D" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
          </span>
        );
      case 4:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
          </span>
        );
      case 5:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
          </span>
        );
      default:
        return <span> No Rating</span>;
    }
  };

  // [markers, setMarker] = useState([]);

  onMapClick = (e) => {
    // http://maps.googleapis.com/maps/api/geocode/json?latlng=44.4647452,7.3553838&sensor=true
    this.setState({
      markers: [
        {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        },
      ],
    });
  };

  render() {
    const jobsArr = _.reverse(this.props.jobs);
    const places = jobsArr.map((place) => {
      const { id } = place;
      return (
        <MapSmallCard
          key={id}
          text={place}
          hover={this.props.hoverKey === id}
          lat={place.lat}
          lng={place.lng}
        />
      );
    });

    return (
      <div>
        <HeaderTags />
        <div className="container-fluid">
          <div style={{ marginBottom: 12 }}>
            <Navigation />
          </div>
          <Col md={8} className="no-margin">
            <div style={{ height: "80vh", width: "100%", marginBottom: 16 }}>
              <GoogleMap
                bootstrapURLKeys={{
                  key: "AIzaSyAXapDoZ0Z_GbKfZGaLbshKJoiH_tmJBAs",
                }}
                defaultCenter={this.state.center}
                center={this.state.center}
                zoom={19}
                onBoundsChange={this._onBoundsChange}
                hoverDistance={K_HOVER_DISTANCE}
                onClick={this.onMapClick}
              >
                {places}
                {this.state.markers.map((marker, index) => (
                  <MapSmallCard
                    key={index}
                    // text={place}
                    // hover={this.props.hoverKey === id}
                    lat={marker.lat}
                    lng={marker.lng}
                  />
                ))}
              </GoogleMap>
            </div>
          </Col>

          <Col md={4} style={{ height: "100%", overflow: "hidden" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              <div style={{ width: "80%" }}>
                <div class="input-group input-group-lg  dropdown-menu-right">
                  <div className="select-container">
                    <select
                      className="select-css"
                      value={this.state.city_name}
                      onChange={this.onStateChange}
                    >
                      {this.state.cities.map((item) => (
                        <option key={item.state_code} value={item.city_name}>
                          {item.city_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div style={{ justifyContent: "flex-end" }}>
                <h3
                  style={{
                    fontWeight: "bold",
                    fontSize: 14,
                    textTransform: "uppercase",
                    lineHeight: 0.5,
                  }}
                >
                  {jobsArr.length} Results
                </h3>
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <ListIndexFilters />
            </div>

            <div style={{ width: "100%", height: "100%" }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "scroll",
                  marginBottom: 80,
                }}
              >
                {jobsArr.length === 0 ? (
                  <></>
                ) : (
                  jobsArr.map((profile) => {
                    const profileImage = profile.img_url ? (
                      <div>
                        <img
                          src={profile.img_url}
                          alt="profile img"
                          className="card-img-container"
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={defaultPhotoURL}
                          alt="profile img"
                          className="card-img-container"
                        />
                      </div>
                    );
                    return (
                      <Col md={12} sm={6} xs={6}>
                        <Link
                          to={`/service-address/${profile.city}/${profile.slug}/`}
                        >
                          <div className="list-card-style">
                            <div
                              style={{
                                height: 180,
                                overflow: "hidden",
                                borderTopRightRadius: 22,
                                borderTopLeftRadius: 22,
                              }}
                            >
                              {profileImage}
                            </div>

                            <div
                              className="list-card"
                              style={{ flexDirection: "row" }}
                            >
                              <div style={{ width: "100%" }}>
                                <div style={{ padding: 4 }}>
                                  <div className="flex-parent">
                                    <p
                                      style={{
                                        fontSize: 14,
                                        fontWeight: "900",
                                      }}
                                      className="long-and-truncated"
                                    >
                                      {profile.displayName}
                                    </p>
                                  </div>
                                  <div className="flex-parent">
                                    <p className="long-and-truncated">
                                      <i className="fas fa-map-marker map-city-icon"></i>{" "}
                                      {profile.address}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="comment-text">
                                      {this.star_rating(profile.rating)}
                                    </p>
                                  </div>
                                  <p>{profile.city}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    );
                  })
                )}
              </div>
            </div>
          </Col>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    userProps: state.userProps,
    jobs: itemSelector(state.jobs, state.filtersText),
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  // startAddReview: (id, reviewBody) => dispatch(startAddReview(id, reviewBody)),
  // fetchProducts: (filters, sort, products) => dispatch(fetchProducts(filters, sort, products)),
  startSetJobs: (data) => dispatch(startSetJobs(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ExploreJobs
);
