import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import firebase from "firebase/compat/app";
import FileUploader from "react-firebase-file-uploader";
import { ToastContainer, toast } from "react-toastify";
var ratings = [
  { star_rating: "Select" },
  { star_rating: 1 },
  { star_rating: 2 },
  { star_rating: 3 },
  { star_rating: 4 },
  { star_rating: 5 },
];

var reasons = [
  { reason: "Select" },
  { reason: "great customer" },
  { reason: "difficult customer" },
  { reason: "late payment" },
  { reason: "no pay" },
  { reason: "other" },
];

class ReviewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ratings: ratings,
      reasons: reasons,
      photoURL: this.props.userProps.photoURL,
      uid: this.props.userProps.uid,
      reason: props.reviewBody ? props.reviewBody.reasons : "",
      id: firebase.database().ref().push().key,
      // company: props.reviewBody ? props.userProps.company : "",
      review_body: props.reviewBody ? props.reviewBody.review_body : "",
      star_rating: props.reviewBody ? props.reviewBody.star_rating : 0,
      createdAt: props.reviewBody
        ? moment(props.reviewBody.createdAt)
        : moment(),
      img_url: props.reviewBody ? props.reviewBody.img_url : [],
      file: [null],
      isUploading: false,
      uploadProgress: 0,
      error: "",
    };
  }
  state = {
    review_body: "",
    star_rating: 0,
    reasons: "",
    createdAt: moment(),
    error: "",
  };

  onSubmit = () => {
    if (!this.state.review_body) {
      this.setState(() => ({ error: "Can't post that" }));
    } else {
      this.setState(() => ({ error: "", review_body: "" }));
      this.props.onSubmit({
        company: this.props.userProps.company,
        review_body: this.state.review_body,
        photoURL: this.state.photoURL,
        id: this.state.id,
        rating: Number(this.state.star_rating),
        reason: this.state.reason,
        createdAt: this.state.createdAt.valueOf(),
        img_url: this.state.img_url,
      });
    }
    toast("Product successfully posted");
  };

  onChangeText = (e) => {
    const review_body = e.target.value;
    this.setState(() => ({ review_body }));
  };

  onChangeRating = (e) => {
    const star_rating = e.target.value;
    this.setState(() => ({ star_rating }));
  };

  onChangeReason = (e) => {
    const reason = e.target.value;
    this.setState(() => ({ reason }));
  };

  // UPLOAD CODE

  handleUploadStart = () =>
    this.setState({
      isUploading: true,
      uploadProgress: 0,
    });

  handleProgress = (progress) =>
    this.setState({
      uploadProgress: progress,
    });

  handleUploadError = (error) => {
    this.setState({
      isUploading: false,
      // Todo: handle error
    });
    console.error(error);
  };

  handleUploadSuccess = (filename) => {
    firebase
      .storage()
      .ref(`users/${this.state.uid}/reviews/${this.state.id}/`)
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        console.log("url::: ", url);
        let imagesArr = [];
        imagesArr.push(url);
        this.setState({ img_url: imagesArr });
      });

    this.setState({ progress: 100, isUploading: false });
  };

  render() {
    const { photoURL } = this.props.userProps;

    const start_reason_selector = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", width: "100%", padding: 16 }}>
          <div>
            <i
              className="fas fa-bullhorn"
              style={{ fontSize: 28, color: "#adadaf" }}
            />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div class="input-group input-group-lg  dropdown-menu-right">
            <div className="select-container">
              <select
                className="select-css-star-rating"
                value={this.state.reason}
                onChange={this.onChangeReason}
              >
                {this.state.reasons.map((item) => (
                  <option key={item.reason} value={item.reason}>
                    {item.reason}{" "}
                    {/* {item.reasons === 1 ? "Star" : "Stars"} */}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    );
    const start_rating_selector = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", width: "100%", padding: 16 }}>
          <div>
            <i
              className="fas fa-star"
              style={{ fontSize: 28, color: "#adadaf" }}
            />
          </div>
        </div>

        <div style={{ width: "100%" }}>
          <div class="input-group input-group-lg  dropdown-menu-right">
            <div className="select-container">
              <select
                className="select-css-star-rating"
                value={this.state.star_rating}
                onChange={this.onChangeRating}
              >
                {this.state.ratings.map((item) => (
                  <option key={item.state_code} value={item.star_rating}>
                    {item.star_rating}{" "}
                    {item.star_rating === 1 ? "Star" : "Stars"}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div>
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 12,
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ marginBottom: 8 }}>
              <p>Select rating</p>
            </div>
            <div className="list-card-style">{start_rating_selector}</div>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ marginBottom: 8 }}>
              <p>Select reason</p>
            </div>
            <div className="list-card-style">{start_reason_selector}</div>
          </div>
        </div>

        <div style={{ marginTop: 12 }}>
          <div>
            <h4>Select Image</h4>
          </div>
          <div>
            <p>Upload proof of service</p>
          </div>
          <div className="new-post-cat-container">
            <div className="new-post-cat-inner-wrapper">
              <FileUploader
                accept="image/*"
                name="image-uploader-multiple"
                storageRef={firebase
                  .storage()
                  .ref(`users/${this.state.uid}/reviews/${this.state.id}/`)}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
              />
              <p>Progress: {this.state.uploadProgress}</p>
            </div>
          </div>
        </div>

        <div className="list-card-style">
          <div className="add-comment-container" onSubmit={this.onSubmit}>
            <div className="add-comment-input-container-style">
              <input
                type="text"
                placeholder="Leave review"
                value={this.state.review_body}
                onChange={this.onChangeText}
                className="add-comment-input-textfield"
              />
            </div>
            <div className="add-comment-button-container">
              <div onClick={this.onSubmit}>
                <div className="add-comment-div-style">Post</div>
              </div>
            </div>
            <div>{this.state.error && <p>{this.state.error}</p>}</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userProps: state.userProps,
  };
};

export default connect(mapStateToProps)(ReviewForm);
