import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "firebase/compat/app";
import moment from "moment";
import { Link } from "react-router-dom";
import _ from "lodash";
import { startRemoveReview, fetchReviews } from "../../actions/reviews";

class Review extends Component {
  startRating() {}
  render() {
    const {
      company,
      displayName,
      review_body,
      reviewId,
      photoURL,
      createdAt,
      rating,
      reason,
      slug,
      img_url
    } = this.props;

    var status_color = (order_status) => {
      switch (order_status) {
        case "no pay":
          return "#f44336";
        case "late payment":
          return "#2196F3";
        case "great customer":
          return "#4CAF50";
        case "Cancelled":
          return "#4CAF50";
        default:
          return "#212121";
      }
    };

    var star_rating = (rating) => {
      switch (rating) {
        case 1:
          return (
            <span>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#f44336" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#cccccc" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#cccccc" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#cccccc" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#cccccc" }}
              ></i>
            </span>
          );
        case 2:
          return (
            <span>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#f44336" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#f44336" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#cccccc" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#cccccc" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#cccccc" }}
              ></i>
            </span>
          );
        case 3:
          return (
            <span>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#FBC02D" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#FBC02D" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#FBC02D" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#cccccc" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#cccccc" }}
              ></i>
            </span>
          );
        case 4:
          return (
            <span>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#388E3C" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#388E3C" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#388E3C" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#388E3C" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#cccccc" }}
              ></i>
            </span>
          );
        case 5:
          return (
            <span>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#388E3C" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#388E3C" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#388E3C" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#388E3C" }}
              ></i>
              <i
                className="fas fa-star"
                style={{ marginRight: "6px", color: "#388E3C" }}
              ></i>
            </span>
          );
        default:
          return <span> No Rating</span>;
      }
    };

    return (
      <div className="review-card-style">
        <div className="review-card-comment-style">
          <div className="list-card">
            <Link to={`/u/${displayName}`}>
              <div
                className="list-profile-avatar-wrapper"
                style={{ paddingLeft: 8 }}
              >
                <img
                  src={photoURL}
                  className="comment-profile-avatar"
                  alt="profile img"
                />
              </div>
            </Link>
            <div className="comment-detail-wrapper">
              <div>
                <p className="comment-display-name">{company}</p>
                <div className="comment-body-text-container">
                  <div>
                    <p className="comment-text">{star_rating(rating)}</p>
                  </div>
                  <div
                    style={{
                      backgroundColor: status_color(reason),
                      marginLeft: 8,
                      // borderColor: status_color(reason),
                      borderRadius: 6,
                      borderWidth: 2,
                      padding: 4,
                      color: "#FFF",
                    }}
                  >
                    <p className="comment-text">{reason}</p>
                  </div>
                </div>
                <p className="comment-text">{review_body}</p>
                <div style={{marginTop: 4, marginBottom: 4}}>
                {img_url !== undefined ? (
                  <div>
                  {img_url.map(image => (
                    <div style={{ width: '100%', overflow: 'hidden', borderRadius: 18}}>
                      <img src={image} style={{ width: '100%', height: 200}}/>
                    </div>
                  ))}
                </div>) : (<></>) }
                </div>
                <p className="timestamp"> 
                <i className="fa fa-clock remove-btn-icon" aria-hidden="true"/>{' '}{moment(createdAt).fromNow()} 
                  {this._removeCommentButton()}
                </p>
              </div>
              
            </div>
            
          </div>
        </div>
      </div>
    );
  }
  _removeCommentButton() {
    const { uid } = this.props.user;
    const {
      company,
      displayName,
      review_body,
      reviewId,
      photoURL,
      createdAt,
      rating,
      slug,
    } = this.props;

    const reviewerUserUid = _.toString(uid);

    const { id } = this.props;

    return this.props.uid === reviewerUserUid ? (
      <div className="remove-comment-btn">
        <div style={{ flexDirection: "column" }}>
          <div>
            <div
              style={{ backgroundColor: "none" }}
              onClick={() => {
                firebase.database().ref(`reviews/${slug}/${reviewId}`).remove();
                this.props.startRemoveReview({ reviewId, slug, uid });
                this.props.fetchReviews(slug);
              }}
            >
              <i className="fa fa-trash remove-btn-icon" aria-hidden="true"></i> Delete
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div></div>
    );
  }
}

const mapStateToProps = (state) => ({
  userProps: state.userProps,
  user: state.user,
});

const mapDispatchToProps = (dispatch, props) => ({
  startRemoveReview: (reviewId, slug, uid) =>
    dispatch(startRemoveReview(reviewId, slug, uid)),
  fetchReviews: (data) => dispatch(fetchReviews(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Review);
