const getBusiness = (businessArr, { text }) => {
  return businessArr.filter((business) => {
    const textMatch = business.address
      .toLowerCase()
      .includes(text?.toLowerCase());
    return textMatch;
  });
};

export default getBusiness;
