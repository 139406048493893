import React, { useState, Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Helmet } from "react-helmet";
import HeroHeader from "./HeroHeader";
import FeaturedProfiles from "./FeaturedProfiles";
import { Grid, Col } from "react-bootstrap";
import firebase from "firebase/compat/app";
import FullWidthCTA from "./FullWidthCTA";
// import firebase from "firebase";
import "../../styles/App.css";
import Layout from "../layout/Layout";
import { Modal, Button } from "react-bootstrap";
// import logo from "../../assets/images/over_age_img@2x.png";
import logo from "../../assets/images/critica_logo@2x.png";
import MidNavBar from "./MidNavBar";
import DownloadAppFullWidthCTA from "./DownloadAppFullWidthCTA";
import Footer from "../layout/Footer";
import BusinessLanding from "../utilities/BusinessLanding";
import Signin_landing from "../signIn/signin_landing";

const HeaderTags = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>CriticaPro - Professional Home Service Network</title>
    <link rel="canonical" href="https://criticapro.com/" />
  </Helmet>
);

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isMounted: false,
      isEmpty: false,
      isFinished: false,
      landingData: {},
      isChecked: false,
      show: false,
    };
  }
  state = {
    isLoading: false,
    landingData: {},
  };

  componentDidMount() {
    this.mounted = true;
  }

  handleClose = () => {
    this.setState({
      show: false,
    });

    const { show } = this.state;
    if (show !== "") {
      localStorage.checkbox = show;
    }
  };

  handleShow = () => {
    this.setState({
      isChecked: !false,
      show: !false,
    });
  };

  onChangeCheckbox = (event) => {
    this.setState({
      isChecked: event.target.checked,
    });
  };

  loginSubmit = () => {
    const { show } = this.state;
    if (show !== "") {
      localStorage.checkbox = show;
    }
  };

  render() {
    return (
      <div>
        <HeaderTags />
        <div style={{ width: "100%" }}>
          <Signin_landing />
          <Footer />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  recent_news: state.recent_news,
});

export default compose(connect(mapStateToProps, undefined))(LandingPage);
