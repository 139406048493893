// Dispensaries Reducer

import { SET_JOBS, ADD_JOB } from "../actions/jobs";

const jobsReducerDefaultState = [];

export default (state = jobsReducerDefaultState, action) => {
  switch (action.type) {
    case SET_JOBS:
      return action.jobs;

    case ADD_JOB:
      return [...state, action.review];
    default:
      return state;
  }
};
