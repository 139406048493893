import React, { Component } from "react";
import { connect } from "react-redux";
import { startAddReview } from "../../actions/reviews";
import _ from "lodash";
import Review from "./Review";
import no_reviews from "../../assets/images/no-reviews@2x.png";
class ReviewsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: [],
      isLoading: true,
      isEmpty: false,
      isFinished: false,
    };
  }

  onSubmit = (reviewBody) => {
    this.props.startAddComment(reviewBody);
  };

  render() {
    const reviewsArray = _.reverse(_.toArray(this.props.reviews));

    return (
      <div>
        <div>
          {this.props.reviews.length == 0 ? (
            <div>
              {reviewsArray.length === 0 ? (
                <div
                  style={{
                    backgroundColor: "#FFF",
                    borderRadius: 22,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "6%",
                    marginTop: 32,
                    marginBottom: 32,
                  }}
                >
                  <div>
                    <img
                      style={{ width: 220 }}
                      src={no_reviews}
                      alt="default state"
                    />
                  </div>
                  <div style={{ marginTop: 24 }}>
                    <p style={{ fontWeight: 600 }}>
                      Be the first to write a review
                      <span role="img" aria-label="star emoji">
                        {" "}
                        ⭐️
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            reviewsArray.map((props, key) => (
              <div className="no-margin">
                <Review {...props} key={key} />
              </div>
            ))
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reviews: state.reviews,
  authUser: state.sessionState.authUser,
});

const mapDispatchToProps = (dispatch, props) => ({
  startAddComment: (id, reviewBody) => dispatch(startAddReview(id, reviewBody)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsView);
