import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { div, div, div, input, button } from "react-bootstrap";
import { auth } from '../../firebase';
import * as routes from '../../constants/routes';
import logo from "../../assets/images/critica_logo_color_dark.svg";
import Layout from "../layout/Layout"

const PasswordForgetPage = () =>
  <Layout>
    <div className="app-wrapper">
      <div className="login-wrapper">
        <div>
          <div xsHidden md={3}></div>
          <div md={6} className="login-modal">
            <a href='/'>
            <img src={logo} className="sign-in-logo" alt="logo" draggable="false" />
            </a>
            <h4 className="login-text">How could you forget your password? Really?</h4>
            <div className="login-well">
              <PasswordForgetForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;

    auth.doPasswordReset(email)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
      })
      .catch(error => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      email,
      error,
    } = this.state;

    const isInvalid = email === '';

    return (
      <div bsSize="large">
      <form onSubmit={this.onSubmit}>
        <div bsSize="large">
          <input
//  button           value={this.state.email}
            onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
            type="text"
            placeholder="Email Address"
          />
        </div>
        <button bsStyle="primary" bsSize="large" className="btn-blank btn-blue" disabled={isInvalid} type="submit">
          Reset My Password
        </button>

        { error && <p>{error.message}</p> }
      </form>
      </div>
    );
  }
}

const PasswordForgetLink = () =>
  <p className="no-margin">
    <Link to={routes.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>

export default PasswordForgetPage;

export {
  PasswordForgetForm,
  PasswordForgetLink,
};
