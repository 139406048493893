import firebase from "firebase/compat/app";

export const FETCH_PROFILE_PROPS = "FETCH_PROFILE_PROPS";
export const FETCH_PROFILE_POSTS = "FETCH_PROFILE_POSTS";

export const getUser = (payload) => ({
  type: "FETCH_PROFILE_PROPS",
  payload,
});

export const fetchUserProfileProps = (place_id) => {
  return async (dispatch) => {
    try {
      const citiesRef = firebase.firestore().collection("usersDB");
      const snapshot = await citiesRef.where("place_id", "==", place_id).get();

      const profiles = [];

      snapshot.forEach((doc) => {
        let profileData = doc.data();
        profiles.push(profileData);
      });

      dispatch(getUser(profiles));
    } catch (error) {
      console.log(error, "error");
    }
  };
};
