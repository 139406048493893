import React from "react";
import landing_image from "../../assets/images/owf_landing_2@2x.png";
import { Grid, Col, Button } from "react-bootstrap";
import Layout from "../layout/Layout";
import { Helmet } from "react-helmet";

const HeaderTags = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>Contact | CriticaPro</title>
    <link rel="canonical" href="https://criticapro.com/" />
  </Helmet>
);

const ContactHeader = () => {
  return (
    <Grid style={{ marginTop: 80 }}>
      <Col md={6} smHidden xsHidden>
        <h1 className="header-h1" style={{ color: "#212121" }}>
          Got any questions?
        </h1>
        <h1 className="header-h2" style={{ color: "#212121" }}>
          Contact the CriticaPro team! support@criticapro.com
        </h1>
      </Col>
      <Col md={6} sm={12}></Col>
    </Grid>
  );
};

const BusinessLanding = () => {
  return (
    <Layout style={{ height: "100vh" }}>
      <HeaderTags />
      <div style={{ marginTop: 80, height: "100vh" }}>
        <ContactHeader />
      </div>
    </Layout>
  );
};
export default BusinessLanding;
