import React, { Component } from "react";
import { Grid } from "react-bootstrap";
import Layout from "../layout/Layout";

export default class TermsAndPrivacy extends Component {
  render() {
    return (
      <Layout>
        <Grid style={{ marginTop: 32, marginBottom: 64 }}>
          <div style={{ margin: 16, paddingTop: 32 }}>
            <p style={{ fontSize: 48, fontWeight: "900", color: "#212121" }}>
              Work with us!
            </p>
            <p style={{ fontWeight: "700" }}>
              We are curretly looking to hire the following positions.
            </p>
          </div>

          <div
            style={{
              marginLeft: 16,
              marginRight: 16,
              marginTop: 16,
              borderRadius: 16,
              elevation: 1,
            }}
          >
            <div style={styles.title_container}>
              <p style={styles.title}>Mobile Developer</p>
            </div>

            <div>
              <p>
                To apply for this position you should be able to develop
                features using React Native, you also should know how to deploy
                an application using services and tools like AppCenter,
                Fastlane, Android Studio, Xcode, and git are all required
                knowledge. You should know how to manage certificates, and how
                to use cloud services on GCP, AWS, and Azure
              </p>
              <p>
                Apply here:{" "}
                <a href="mailto:assist@okieweedfinder.com">Email resume here</a>
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>Mobile Developer</p>
            </div>

            <div>
              <p>
                To apply for this position you should be able to develop
                features using React Native, you also should know how to deploy
                an application using services and tools like AppCenter,
                Fastlane, Android Studio, Xcode, and git are all required
                knowledge. You should know how to manage certificates, and how
                to use cloud services on GCP, AWS, and Azure
              </p>
              <p>
                Apply here:{" "}
                <a href="mailto:assist@okieweedfinder.com">Email resume here</a>
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>Mobile Developer</p>
            </div>

            <div>
              <p>
                To apply for this position you should be able to develop
                features using React Native, you also should know how to deploy
                an application using services and tools like AppCenter,
                Fastlane, Android Studio, Xcode, and git are all required
                knowledge. You should know how to manage certificates, and how
                to use cloud services on GCP, AWS, and Azure
              </p>
              <p>
                Apply here:{" "}
                <a href="mailto:assist@okieweedfinder.com">Email resume here</a>
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>QA Engineer</p>
            </div>

            <div>
              <p>
                To apply for this position you should be have an understanding
                of how to test a mobile aplication and know the ups and downs of
                testing in multimple devices and ecosystems. You know be able to
                decribe bug and app defects, amazing written and verbal
                communication skills, and be able to work in a team environment.
              </p>
              <p>
                Apply here:{" "}
                <a href="mailto:assist@okieweedfinder.com">Email resume here</a>
              </p>
            </div>

            <div style={styles.title_container}>
              <p style={styles.title}>Content manager</p>
            </div>

            <div>
              <p>
                We are lookign for someone who is able to manage the content of
                CriticaPro mobile application. You should be able to manage the
                content of our social media pages. As well has have knowledge of
                the industry and have great taste.
              </p>
              <p>
                Apply here:{" "}
                <a href="mailto:assist@okieweedfinder.com">Email resume here</a>
              </p>
            </div>
          </div>
        </Grid>
      </Layout>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    paddingTop: 90,
  },
  detailsContainer: {
    padding: 16,
    margin: 10,
    backgroundColor: "#ffffff",
    borderRadius: 10,
    shadowColor: "#AAAAAA",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 5,
  },
  title_container: {
    marginTop: 20,
  },
  title: {
    fontWeight: "bold",
  },
};
