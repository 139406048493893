// Users Reducer
import { FETCH_USER, FETCH_USER_DETAILS, EDIT_USER, FETCH_USER_REVIEWS } from '../actions/userProps'

const defaultUserState = {};

export default (state = defaultUserState, action) => {
  switch (action.type) {

    case FETCH_USER:
      return action.payload;
    
    case FETCH_USER_DETAILS:
      return action.payload;
    
      case FETCH_USER_REVIEWS:
      return action.payload;

    case EDIT_USER:    
      return action.updates;

    default:
      return state;
  }
};
