// Reviews Reducer
import { SET_REVIEWS, REMOVE_REVIEW, ADD_REVIEW } from "../actions/reviews";

const reviewsReducerDefaultState = [];

export default (state = reviewsReducerDefaultState, action) => {
  switch (action.type) {
    case SET_REVIEWS:
      return action.reviews;

    // case ADD_REVIEW:
    //   return [...state, action.review];

    case REMOVE_REVIEW:
      return state.filter(({ id }) => id !== action.id);
    default:
      return state;
  }
};
