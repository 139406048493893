
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Grid,
  Col,
  Button,
} from "react-bootstrap";
import { compose } from "recompose";
import itemSelector from "./../../selectors/selector";
import _ from "lodash";
import Layout from "../layout/Layout";
import LoadingPage from "../utilities/LoadingPage";
import SidebarContentAuth from "../dashboard/SidebarContentAuth";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { startGetUserReviews } from "../../actions/userReviewsProps";
import Review from "./Review";

const K_HOVER_DISTANCE = 100 / 2;

class ReviewsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: {},
      displayName: "",
      city_name: "",
      user_search: null,
      zoomToMarkers: null,
      zoom: null,
      show: false,
      show_modal: false,
      show_modal_sign_up: false,
      center: null,
      latLng: null,
      addressSet: null,
      address: "",
      latLng: {},
      slug: "",
      service_address: [],
    };
    
  }

  componentDidMount() {
    
    const { uid } = this.props.user;
    
    this.mounted = true;

    if (this.mounted) {
      this.props.startGetUserReviews(uid);
      console.log('CALLED::: ');
    }

  }

  static defaultProps = {
    zoom: 12,
  };


  star_rating = (rating) => {
    switch (rating) {
      case 1:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#f44336" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
          </span>
        );
      case 2:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#f44336" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#f44336" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
          </span>
        );
      case 3:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#FBC02D" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#FBC02D" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#FBC02D" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
          </span>
        );
      case 4:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
          </span>
        );
      case 5:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
          </span>
        );
      default:
        return <span> No Rating</span>;
    }
  };

  onMapClick = (e) => {
    console.log("e::: ", e);
    // http://maps.googleapis.com/maps/api/geocode/json?latlng=44.4647452,7.3553838&sensor=true
    // this.setState({
    //   latLng: [
    //     {
    //       lat: e.latLng.lat(),
    //       lng: e.latLng.lng(),
    //     },
    //   ],
    // });
  };

  // onMapLoad = map => {
  //   let marker = new google.maps.Marker({
  //   position: markerPosition,
  //   map: map,
  //   draggable: true,
  // })
  // marker.addListener('dragend', handleDragEnd)
  // }

  // handleDragEnd = e => {
  //     console.log(e.latLng)
  //   }

  // renderMarkers(map, maps) {
  //     let marker = new maps.Marker({
  //       position: this.state.latLng,
  //       map,
  //       title: 'Hello World!'
  //     });
  //   }

  
  render() {
    const reviewsArr = _.reverse(_.toArray(this.props.userReviewsProps));
    const reaviewLocationsArr = [];
    // const street_view_img = `https://maps.googleapis.com/maps/api/streetview?size=800x800&location=${this.state.latLng.lat},${this.state.latLng.lng}&fov=80&heading=-120&pitch=10&key=AIzaSyAXapDoZ0Z_GbKfZGaLbshKJoiH_tmJBAs`;
    return (
      <Layout>
        <Grid fluid style={{ height: "100vh" }}>
          <div>
            <Col md={2} lg={2} smHidden xsHidden style={{ marginTop: 12 }}>
              <SidebarContentAuth />
            </Col>
            <Col md={10} sm={12} xs={12}>
              <Col md={9} className="no-margin">
                <div
                  style={{ height: "100vh", width: "100%", marginBottom: 16 }}
                >
      <Layout>
        <Grid fluid style={{ height: "100vh", marginTop: 32 }}>
          <div>
            <Col md={10} sm={12} xs={12}>
              <h1>Review</h1>
              {reviewsArr.length === 0 ? (
                <LoadingPage />
              ) : (
                reviewsArr.map((props) => (
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <Review {...props} />
                  </div>
                ))
              )}
            </Col>
          </div>
        </Grid>
      </Layout>
                </div>
              </Col>

              <Col md={3} style={{ height: "100%", overflow: "hidden" }}>
                {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 8,
                    }}
                  >
                    <div style={{ width: "80%" }}>
                      <div class="input-group input-group-lg  dropdown-menu-right">
                        <div className="select-container">
                          <select
                            className="select-css"
                            value={this.state.city_name}
                            onChange={this.onStateChange}
                          >
                            {this.state.cities.map((item) => (
                              <option
                                key={item.state_code}
                                value={item.city_name}
                              >
                                {item.city_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div style={{ justifyContent: "flex-end" }}>
                      <h3
                        style={{
                          fontWeight: "bold",
                          fontSize: 14,
                          textTransform: "uppercase",
                          lineHeight: 0.5,
                        }}
                      >
                        {jobsArr.length} Results
                      </h3>
                    </div>
                  </div> */}
                <h1 style={{ fontSize: 38, fontWeight: "bold" }}>
                  Search address
                </h1>
                
                <div style={{ width: "100%" }} className="search-card-style">

                  <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            overflow: "hidden",
                            borderRadius: 12,
                            marginBottom: 12,
                            paddingLeft: 8,
                            background: "#FFF",
                            alignItems: "center",
                          }}
                        >
                          {this.state.showAddress ? (
                            <></>
                          ) : (
                            <div>
                              <i
                                className="fas fa-search"
                                style={{
                                  marginRight: "6px",
                                  fontSize: 20,
                                  marginRight: 12,
                                }}
                              ></i>
                            </div>
                          )}

                          <input
                            style={{ height: 60, width: "100%" }}
                            {...getInputProps({
                              placeholder: "Search for service address",
                              className: "location-search-input",
                            })}
                          />
                          {this.state.showAddress ? (
                            <div
                              onClick={() => {
                                this.props.fetchReviews("");
                                // this.props.fetchUserProfileProps("");

                                this.setState({
                                  addressSet: false,
                                  showAddress: false,
                                  address: "",
                                });
                              }}
                            >
                              <i
                                className="fas fa-window-close"
                                style={{
                                  marginRight: "6px",
                                  fontSize: 20,
                                  marginRight: 12,
                                }}
                              ></i>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>

                
              </Col>
            </Col>
          </div>

        </Grid>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    authUser: state.sessionState.authUser,
    userReviewsProps: state.userReviewsProps,
    jobs: itemSelector(state.jobs, state.filtersText),
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  startGetUserReviews: (data) => dispatch(startGetUserReviews(data)),

});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ReviewsView
);
