import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Grid,
} from "react-bootstrap";
import { connect } from "react-redux";
import { startAddPost } from "../../actions/posts";
import logo from "../../assets/images/critica_logo_color_dark.svg";
import Sidebar from "react-sidebar";
import * as routes from "../../constants/routes";
import firebase from "firebase/compat/app";
import { auth } from "../../firebase";
// import FloatCart from '../FloatCart'
import PostForm from "../posts/PostForm";
import SearchUser from "../explore/";
import SidebarContentAuth from "./SidebarContentAuth";

class Navigation extends Component {
  onSubmit = (post) => {
    this.props.startAddPost(post);
  };
  render() {
    return (
      <div>
        {this.props.authUser ? (
          <NavigationAuth
            startAddPost={this.props.startAddPost}
            onSubmit={this.onSubmit}
            user={this.props.user}
          />
        ) : (
          <NavigationNonAuth />
        )}
      </div>
    );
  }
}

class NavigatioMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
    };
  }

  onSetSidebarOpen() {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }

  render() {
    return (
      <div>
        {/* <Sidebar
          sidebar={<SidebarContentAuth />}
          open={this.state.sidebarOpen}
          styles={{ sidebar: { background: "white" } }}
        ></Sidebar> */}
      </div>
    );
  }
}

class NavigationAuth extends Component {
  // constructor(props) {
  //   super(props);
  //   this.handleShow = this.handleShow.bind(this);
  //   this.handleHide = this.handleHide.bind(this);
  //   this.state = {
  //     show: false,
  //     photoURL: null,
  //     displayName: "",
  //   };
  // }

  // handleShow() {
  //   this.setState({ show: true });
  // }
  // handleHide() {
  //   this.setState({ show: false });
  // }
  // onSubmit = (post) => {
  //   const uid = firebase.auth().currentUser.uid;

  //   (function () {
  //     const followersRef = firebase.database().ref(`/followers/${uid}`);

  //     var followers_ = {};

  //     followersRef.on("value", (snapshot) => {
  //       var followers = Object.keys(snapshot.val());
  //       followers_ = snapshot.val();
  //       followers = Object.keys(followers_);
  //       var fanoutObj = {};
  //       fanoutObj[`users/${uid}/posts/` + post.id] = post;
  //       followers.forEach(
  //         (key) => (fanoutObj["/timeline/" + key + "/" + post.id] = post)
  //       );
  //       fanoutObj["posts/" + post.id] = post;
  //       firebase.database().ref().update(fanoutObj);
  //     });
  //   })();
  //   this.setState({ show: false });
  // };

  render() {
    return (
      <div>
        <NavigatioMobile />
        <Grid fluid className="no-margin">
          <Col md={12} lg={12} smHidden xsHidden className="no-margin">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
              }}
            ></div>
          </Col>
        </Grid>
        {/* 
        <div>
          <Modal
            {...this.props}
            show={this.state.show}
            onHide={this.handleHide}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-lg">
                Share new post!
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#f5f5f7", width: "100%" }}>
              <PostForm onSubmit={this.onSubmit} />
            </Modal.Body>
          </Modal>
        </div> */}
      </div>
    );
  }
}

class NavigationNonAuth extends Component {
  render() {
    return (
      <div>
        <Grid fluid className="no-margin"></Grid>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startAddPost: (post) => dispatch(startAddPost(post)),
});

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
  userProps: state.userProps,
  user: state.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
