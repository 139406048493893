import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import * as routes from "../../constants/routes";

import ScrollToTop from "../utilities/ScrollToTop";
import SignUpPage from "../signup";
import SignUpBusinessPage from "../signup/signupBusiness";
import SignInPage from "../signIn";
import Landing from "../landing";
import PasswordForgetPage from "../passwordForget";
import Dashboard from "../dashboard";
import Timeline from "../timeline";
import OrderDetail from "../dashboard/OrderDetail";
import AccountPage from "../account";
import withAuthentication from "../session/withAuthentication";
import Blog from "../blog";
import BlogDetail from "../blog/PostDetailView";
import Jobs from "../jobs/";
import DispensaryDashboard from "../jobs/DispensaryDashboard";
import JobProfile from "../jobs/profile";
import DispensaryProductDetail from "../jobs/ProductDetail";
import Doctors from "../doctors/";
import DoctorProfile from "../doctors/profile";
import Processors from "../processors/";
import ProcessorProfile from "../processors/profile";
import Growers from "../growers/";
import GrowerProfile from "../growers/profile";
import TestingLabs from "../testing_labs/";
import TestingLabProfile from "../testing_labs/profile";
import EditProductView from "../products/EditProductView";
import PublicUserProfile from "../profile";
import FollowingFriendsList from "../friends/FollowingFriendsList";
import FollowersFriendsList from "../friends/FollowersFriendsList";
import PublicUserProfileDetail from "../timeline/ListDetail";
import AddProductView from "../products/AddProductView";
import AddNotificationView from "../notifications/AddProductView";
import AddPostView from "../posts/AddPostView";
import Explore from "../explore/Discovery";
import Reviews from "../reviews";
import AddBusinessView from "../business/AddBusinessView";
import AddReviewView from "../reviews/AddReviewView";
import AvailableForms from "../utilities/AvailableForms";
import MedicalMarijuana from "../utilities/MedicalMarijuana";
import BusinessLanding from "../utilities/BusinessLanding";
import Contact from "../utilities/Contact";
import MobileApp from "../utilities/MobileApp";
import ProductsView from "../dashboard/ProductsView";
import AppAbout from "../utilities/AppAbout";
import AppTerms from "../utilities/AppTerms";
import AppPrivacy from "../utilities/AppPrivacy";
import Room from "../livestream/Room";
import StreamRoom from "../livestream/StreamRoom";
import Careers from "../utilities/Careers";
import About from "../utilities/About";
import Terms from "../utilities/Terms";
import Privacy from "../utilities/Privacy";
import Download from "../download";

export const history = createHistory();

const App = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route exact path={routes.LANDING} component={DispensaryDashboard} />
        <Route exact path={routes.SIGN_IN} component={SignInPage} />
        <Route exact path={routes.SIGN_UP} component={SignUpPage} />
        <Route
          exact
          path={routes.SIGN_UP_BUSINESS}
          component={SignUpBusinessPage}
        />
        <Route exact path={routes.TIMELINE} component={Timeline} />
        <Route exact path={routes.REVIEWS} component={Reviews} />
        <Route exact path={routes.PRODUCT_NEW} component={AddProductView} />
        <Route exact path={routes.POST_NEW} component={AddPostView} />
        <Route exact path={routes.PRODUCT_EDIT} component={EditProductView} />
        <Route exact path={routes.BUSINESS_NEW} component={AddBusinessView} />
        <Route exact path={routes.REVIEW_NEW} component={AddReviewView} />
        <Route exact path={routes.ACCOUNT} component={AccountPage} />
        <Route exact path={routes.USER_PROFILE} component={PublicUserProfile} />
        <Route exact path={routes.JOBS} component={Jobs} />
        <Route exact path={routes.JOB_PROFILE} component={JobProfile} />
        <Route exact path={routes.CONTACT} component={Contact} />
        <Route
          exact
          path={routes.PASSWORD_FORGET}
          component={PasswordForgetPage}
        />
        <Route exact path={routes.ABOUT} component={About} />
        <Route exact path={routes.PRIVACY} component={Privacy} />
        <Route exact path={routes.TERMS} component={Terms} />
        <Route exact path={routes.APP_ABOUT} component={AppAbout} />
        <Route exact path={routes.APP_PRIVACY} component={AppPrivacy} />
        <Route exact path={routes.APP_TERMS} component={AppTerms} />
        <Route exact path={routes.DOWNLOAD} component={Download} />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default withAuthentication(App);
