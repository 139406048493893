import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  greatPlaceStyle,
  smallCard,
} from "./my_great_place_with_hover_styles.js";
import map_marker from "../../assets/images/map_marker@2x.png";

export default class MyGreatPlaceWithHover extends Component {
  render() {
    return (
      <div>
        <div>
          <img
            src={map_marker}
            style={{ width: 40, height: 40 }}
            alt="map marker img"
          />
        </div>
      </div>
    );
  }
}
