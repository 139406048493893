import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as routes from "../../constants/routes";
import { compose } from "recompose";
// import ListIndexFilters from "./ListIndexFilters";
import { startSetDispensaries } from "../../actions/dispensaries";
import itemSelector from "./../../selectors/selector";
// import div from "../layout/div";
import GoogleMap from "google-map-react";
import _ from "lodash";
import { fetchReviews, startAddReview } from "../../actions/reviews";
import logo from "../../assets/images/critica_logo_color_dark.svg";
import { fetchUserProfileProps } from "../../actions/profile";
import { Button, Modal } from "react-bootstrap";
import ReviewForm from "../reviews/ReviewForm";
// import map_marker from '../../assets/images/map_marker.png'
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { Col } from "react-bootstrap";
// import HeroHeader from '../landing/HeroHeader';
// import dispensaries from "../../reducers/dispensaries";
import MapSmallCard from "./MapSmallCard";
import LoadingPage from "../utilities/LoadingPage";
import Navigation from "../layout/Navigation";
import Footer from "../layout/Footer";
import ReviewsView from "../reviews/ReviewsView";
import defaultPhotoURL from "../../assets/images/default_avatar.jpg";
// const K_MARGIN_TOP = 30;
// const K_MARGIN_RIGHT = 30;
// const K_MARGIN_BOTTOM = 30;
// const K_MARGIN_LEFT = 30;
const K_HOVER_DISTANCE = 100 / 2;

// const bounds = {
//   nw: {
//     lat: 50.01038826014866,
//     lng: -118.6525866875
//   },
//   se: {
//     lat: 32.698335045970396,
//     lng: -92.0217273125
//   }
// };

// Or
// const K_SCALE_NORMAL = 0.65;

// const bounds = {
//   ne: {
//     lat: 36.5312376,
//     lng: -96.6935472
//   },
//   sw: {
//     lat: 34.230039,
//     lng: -98.800048
//   }
// };

// const size = {
//   width: 640, // Map width in pixels
//   height: 380, // Map height in pixels
// };

// const {center, zoom} = fitBounds(bounds, size);

const defaultMapOptions = {
  disableDefaultUI: false,
};
const waterStyle = [
  {
    // featureType: "water",
    // elementType: "geometry.fill",
    // stylers: [
    //   {
    //     color: "#4BB4F5",
    //   },
    // ],
  },
];
class ExploreDispensaries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: {},
      displayName: "",
      city_name: "",
      user_search: null,
      zoomToMarkers: null,
      zoom: null,
      center: {},
      address: "",
      latLng: {},
      addressSet: false,
      show: false,
      slug: null,
      showAddress: false,
    };
    this.handleHideReviewForm = this.handleHideReviewForm.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleHideReviewForm() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleSelect = (address) => {
    console.log("address::: ", address);
    geocodeByAddress(address)
      .then((results) => {
        console.log("results[0])::: ", results[0]);
        getLatLng(results[0]).then((latLng) => {
          this.setState({
            address,
            latLng,
            slug: results[0].place_id,
            center: latLng,
          });
          this.props.fetchReviews(results[0].place_id);
          this.props.fetchUserProfileProps(results[0].place_id);
          this.setState({ addressSet: true, showAddress: true });
        });
      })
      .catch((error) => console.error("Error", error));
  };

  componentDidMount() {
    if (_.toArray(this.props.serviceObj).length === 0) {
      this.getLocation();
    } else {
      this.setState({ center: this.props.serviceObj });
    }
  }

  _onBoundsChange = (center, zoom, bounds, marginBounds) => {
    if (this.props.onBoundsChange) {
      this.props.onBoundsChange({ center, zoom, bounds, marginBounds });
    } else {
      this._onChange(center);
    }
  };

  _onChange({ center, zoom, bounds, ...other }) {
    // unstable_batchedUpdates(() => {
    //  this.props.onZoomChange(zoom);
    // change center here
    this.setState({ zoom: zoom });
    // });
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // console.log(position, "Position")
        this.setState({ center: pos });
        console.log("pos::: ", pos);
      });
    } else {
      this.setState({ err: "Geolocation is not supported by this browser." });
    }
  }

  render() {
    console.log(
      "authUser: state.sessionState.authUser::: ",
      this.props.authUser
    );

    const serviceArr = this.props.serviceObj;
    const street_view_img = `https://maps.googleapis.com/maps/api/streetview?size=800x800&location=${this.state.latLng.lat},${this.state.latLng.lng}&fov=80&heading=-120&pitch=10&key=AIzaSyAXapDoZ0Z_GbKfZGaLbshKJoiH_tmJBAs`;
    // console.log(this.state.position, "Position");
    // console.log(this.props.serviceObj, "serviceObj");

    // const places = () => {
    //   // const { id } = place;
    //   return (
    //     <MapSmallCard
    //       key={1}
    //       // text={""}
    //       // hover={this.props.hoverKey === 1}
    //       lat={this.props.serviceObj.lat}
    //       lng={this.props.serviceObj.lng}
    //     />
    //   );
    // };

    return (
      <div>
        <div
          style={{
            display: "flex",
            flex: 1,
            position: "absolute",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              width: "100%",
            }}
          >
            <div>
              {serviceArr === null ? (
                <LoadingPage />
              ) : (
                <div>
                  <div
                    style={{
                      position: "absolute",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <GoogleMap
                      // defaultOptions={defaultMapOptions}
                      options={{
                        disableDefaultUI: true,
                      }}
                      bootstrapURLKeys={{
                        key: "AIzaSyAXapDoZ0Z_GbKfZGaLbshKJoiH_tmJBAs",
                      }}
                      defaultCenter={this.state.center}
                      center={this.state.center}
                      zoom={17}
                      onBoundsChange={this._onBoundsChange}
                      hoverDistance={K_HOVER_DISTANCE}
                    >
                      {/* {places} */}
                      <MapSmallCard
                        // key={1}
                        // text={""}
                        // hover={this.props.hoverKey === 1}y
                        lat={this.props.serviceObj.lat}
                        lng={this.props.serviceObj.lng}
                      />
                    </GoogleMap>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="landing_header_container">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ marginBottom: 40 }}>
              <img
                src={logo}
                className="app-logo-header"
                alt="criticapro logo"
                draggable="false"
              />
            </div>
          </div>
          <div className="search-card-style">
            <PlacesAutocomplete
              value={this.state.address}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      overflow: "hidden",
                      borderRadius: 12,
                      marginBottom: 12,
                      paddingLeft: 8,
                      background: "#FFF",
                      alignItems: "center",
                    }}
                  >
                    {this.state.showAddress ? (
                      <></>
                    ) : (
                      <div>
                        <i
                          className="fas fa-search"
                          style={{
                            marginRight: "6px",
                            fontSize: 20,
                            marginRight: 12,
                          }}
                        ></i>
                      </div>
                    )}

                    <input
                      style={{ height: 60, width: "100%" }}
                      {...getInputProps({
                        placeholder: "Search for service address",
                        className: "location-search-input",
                      })}
                    />
                    {this.state.showAddress ? (
                      <div
                        onClick={() => {
                          this.props.fetchReviews("");
                          this.props.fetchUserProfileProps("");

                          this.setState({
                            addressSet: false,
                            showAddress: false,
                            address: "",
                          });
                        }}
                      >
                        <i
                          className="fas fa-window-close"
                          style={{
                            marginRight: "6px",
                            fontSize: 20,
                            marginRight: 12,
                          }}
                        ></i>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          {this.props.authUser == null ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div>
                <Link to={routes.BUSINESS_LANDING}>
                  <Button
                    className="btn landing-btn"
                    style={{ marginTop: 8, marginRight: 8, marginBottom: 18 }}
                  >
                    <p>
                      <strong>Sign up</strong>
                    </p>
                  </Button>
                </Link>
              </div>
              <div>
                <Link to={routes.SIGN_IN}>
                  <Button
                    className="btn landing-btn"
                    style={{ marginTop: 8, marginRight: 8, marginBottom: 18 }}
                  >
                    <p>
                      <strong>Login</strong>
                    </p>
                  </Button>
                </Link>
              </div>
            </div>
          ) : (
            <></>
          )}

          {this.state.showAddress ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                flexDirection: "column",
              }}
            >
              <div className="list-card-style">
                <div
                  style={{
                    height: "100%",
                    overflow: "hidden",
                    borderTopRightRadius: 22,
                    borderTopLeftRadius: 22,
                  }}
                >
                  <div>
                    <img
                      src={street_view_img}
                      alt="profile img"
                      className="card-img-container"
                    />
                  </div>
                </div>

                <div
                  style={{
                    marginTop: 8,
                    marginLeft: 12,
                    marginRight: 12,
                    flexDirection: "row",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <div style={{ padding: 4 }}>
                      <div className="flex-parent">
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: "900",
                          }}
                          className="long-and-truncated"
                        >
                          <i className="fas fa-map-marker map-city-icon"></i>
                          {"  "}
                          {this.state.address}
                        </p>
                      </div>
                      <div className="flex-parent">
                        <p
                          className="long-and-truncated"
                          style={{
                            fontSize: 16,
                          }}
                        >
                          <i className="fas fa-bullhorn map-city-icon"></i>
                          {"  "}
                          <strong>{this.props.reviews.length}</strong> reviews
                        </p>
                      </div>
                      <div>
                        <p className="comment-text">
                          {/* {this.star_rating(profile.rating)} */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{ borderRadius: 24, overflow: "hidden", margin: 12 }}
                >
                  <div
                    style={{
                      position: "relative",
                      height: 200,
                      width: "100%",
                    }}
                  >
                    <GoogleMap
                      options={{
                        disableDefaultUI: true,
                      }}
                      bootstrapURLKeys={{
                        key: "AIzaSyAXapDoZ0Z_GbKfZGaLbshKJoiH_tmJBAs",
                      }}
                      defaultCenter={this.state.center}
                      center={this.state.center}
                      zoom={17}
                      // onBoundsChange={this._onBoundsChange}
                      hoverDistance={K_HOVER_DISTANCE}
                    >
                      {/* {places} */}
                      <MapSmallCard
                        // key={1}
                        // text={""}
                        // hover={this.props.hoverKey === 1}
                        lat={this.props.serviceObj.lat}
                        lng={this.props.serviceObj.lng}
                      />
                    </GoogleMap>
                  </div>
                </div>

                <div>
                  <div
                    className="col-md-12 col-sm-12 col-xs-12"
                    style={{
                      backgroundColor: "#FFF",
                      paddingTop: 8,
                      paddingBottom: 8,
                    }}
                  >
                    {this.props.authUser !== null ? (
                      <div>
                        <Button
                          className="btn-lg add-new-post-btn"
                          onClick={this.handleShow}
                          style={{ width: "100%", marginBottom: 8 }}
                        >
                          <p style={{ color: "#FFFFFF" }}>
                            <i
                              className="fas fa-pencil-alt"
                              style={{ marginRight: "6px" }}
                            ></i>
                            <strong>Write a service review</strong>
                          </p>
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Link to={routes.BUSINESS_LANDING}>
                          <Button
                            className="btn-lg add-new-post-btn"
                            style={{ width: "100%", marginBottom: 8 }}
                          >
                            <p style={{ color: "#FFFFFF" }}>
                              <i
                                className="fas fa-pencil-alt"
                                style={{ marginRight: "6px" }}
                              ></i>
                              <strong>Write a service review</strong>
                            </p>
                          </Button>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                <ReviewsView />
              </div>
              <div
                style={{
                  borderBottomLeftRadius: 24,
                  borderBottomRightRadius: 24,
                  overflow: "hidden",
                  marginBottom: 100,
                }}
              >
                <Footer />
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div>
          <Modal
            {...this.props}
            show={this.state.show}
            onHide={this.handleHideReviewForm}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-lg">
                <h3>{this.state.address} Review</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#f5f5f7", width: "100%" }}>
              <ReviewForm
                reviewBody={this.props.reviewBody}
                onSubmit={(reviewBody) => {
                  this.props.startAddReview(this.state.slug, reviewBody);
                  // this.props.fetchReviews(this.state.slug);
                }}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.sessionState.authUser,
    user: state.user,
    profile: state.profile,
    reviews: state.reviews,
    // userProps: state.userProps,
    // dispensaries: itemSelector(state.dispensaries, state.filtersText),
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  startAddReview: (id, reviewBody) => dispatch(startAddReview(id, reviewBody)),
  // fetchProducts: (filters, sort, products) => dispatch(fetchProducts(filters, sort, products)),
  fetchReviews: (data) => dispatch(fetchReviews(data)),
  startSetDispensaries: (data) => dispatch(startSetDispensaries(data)),
  fetchUserProfileProps: (data) => dispatch(fetchUserProfileProps(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ExploreDispensaries
);
