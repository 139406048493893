import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import Layout from "../layout/Layout";
import _ from "lodash";
import ProfileSidebar from "./ProfileSidebar";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { fetchProducts } from "../../services/shelf/actions";
import LoadingPage from "../utilities/LoadingPage";
import ProductList from "../Shelf/ProductList";
import "../Shelf/style.scss";

import { Button, Modal } from "react-bootstrap";
import sign_up_img_modal from "../../assets/images/sign_up_modal_img@2x.png";
import * as routes from "../../constants/routes";
import ReviewForm from "../reviews/ReviewForm";
import { fetchReviews, startAddReview } from "../../actions/reviews";
import { fetchUserProfileProps } from "../../actions/profile";
import LocalMap from "./LocalMap";
import defaultPhotoURL from "../../assets/images/default_avatar.jpg";
import ReviewsView from "../reviews/ReviewsView";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

class PublicProfile extends Component {
  static propTypes = {
    fetchProducts: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    filters: PropTypes.array,
    sort: PropTypes.string,
  };

  state = {
    isLoading: false,
    profileData: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      latLng: { lat: 123123, lng: 2342343 },
      isLoading: true,
      isMounted: false,
      isEmpty: false,
      isFinished: false,
      profileData: {},
      profileProducts: {},
      show: false,
      profileDataListing: [],
      data: null,
    };
    this.handleShowReviewForm = this.handleShowReviewForm.bind(this);
    this.handleHideReviewForm = this.handleHideReviewForm.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
  }

  getBusinessProfile = async () => {
    // const slug = this.props.match.params.slug;
    try {
      // const citiesRef = firebase.firestore().collection("dispensariesDB");
      // const snapshot = await citiesRef.where("slug", "==", slug).get();

      // let profiles = [];

      // snapshot.forEach((doc) => {
      //   let dispoData = doc.data();
      //   profiles.push(dispoData);
      // });
      this.setState({ profileData: this.props.profile[0] });

      // this.getBusinessProducts(profiles[0].uid);
    } catch (error) {
      console.log(error, "error");
    }
  };
  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0]).then((latLng) => {
          this.setState({
            address,
            latLng,
            slug: results[0].place_id,
            center: latLng,
          });
          this.props.fetchReviews(results[0].place_id);
          this.props.fetchUserProfileProps(results[0].place_id);
          this.setState({ addressSet: true, showAddress: true });
        });
      })
      .catch((error) => console.error("Error", error));
  };
  getBusinessProducts = async (uid) => {
    try {
      const db = firebase.firestore().collection("usersDB");
      let productArr = await db.doc(`${uid}`).collection("product").get();

      let productsArrayTemp = [];

      productArr.forEach((doc) => {
        let dispoData = doc.data();
        productsArrayTemp.push(dispoData);
      });

      this.setState({ profileProducts: productsArrayTemp });
      this.setState({ isLoading: false });
    } catch (error) {
      console.log(error, "error");
    }
  };

  componentDidMount() {
    const slug = this.props.match.params.slug;
    // const city = this.props.match.params.city;
    this.mounted = true;

    if (this.mounted) {
      this.getBusinessProfile();
      // this.props.fetchReviews(slug);
    }
  }

  handleShowReviewForm() {
    this.setState({ show: true });
  }
  handleShow() {
    this.setState({ show: true });
  }
  handleHideReviewForm() {
    this.setState({ show: false });
  }
  handleHide() {
    this.setState({ show: false });
  }
  onSubmit = (reviewBody) => {
    this.props.startAddReview(reviewBody);
  };

  render() {
    const slug = this.props.match.params.uid;
    const city = this.props.match.params.id;
    const lat = this.props.match.params.lat;
    const lng = this.props.match.params.lng;

    const result = this.props.match.params.slug;
    const split_coord = result.split(",");
    console.log("split_coord", split_coord);
    // console.log("result", result[0]);
    // console.log("result", result[1]);

    const { isLoading } = this.state;
    let calcMargin = this.props.authUser ? 30 : 80;
    return (
      <Layout>
        <div className="container-fluid">
          <div
            className="app-wrapper"
            style={{ marginTop: 24, marginBottom: 100 }}
          >
            {/* <div>
              <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        overflow: "hidden",
                        borderRadius: 12,
                        marginBottom: 12,
                        paddingLeft: 8,
                        background: "#FFF",
                        // display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <i
                        className="fas fa-search"
                        style={{
                          marginRight: "6px",
                          fontSize: 20,
                          marginRight: 12,
                        }}
                      ></i>
                      <input
                        style={{ height: 60, width: "100%" }}
                        {...getInputProps({
                          placeholder: "Search for service address",
                          className: "location-search-input",
                        })}
                      />
                    </div>
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div> */}

            {!this.state.showAddress ? (
              <Link to={`/job/owasso/slug/`}>
                <div
                  style={{
                    // height: "100%",
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="review-card-style"
                    style={{
                      borderTopLeftRadius: 22,
                      borderTopRightRadius: 22,
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        overflow: "hidden",
                        borderTopRightRadius: 22,
                        borderTopLeftRadius: 22,
                      }}
                    >
                      <div>
                        <img
                          src={`https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${split_coord[0]},${split_coord[1]}&fov=80&heading=-120&pitch=10&key=AIzaSyAXapDoZ0Z_GbKfZGaLbshKJoiH_tmJBAs`}
                          alt="profile img"
                          className="card-img-container"
                        />
                      </div>
                    </div>

                    <div className="list-card" style={{ flexDirection: "row" }}>
                      <div style={{ width: "100%" }}>
                        <div style={{ padding: 4 }}>
                          <div className="flex-parent">
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: "900",
                              }}
                              className="long-and-truncated"
                            >
                              <i className="fas fa-map-marker map-city-icon"></i>{" "}
                              {this.props.profile[0]?.address}
                            </p>
                          </div>
                          <div className="flex-parent">
                            <p className="long-and-truncated">
                              {/* <i className="fas fa-map-marker map-city-icon"></i>{" "} */}
                              <strong>{this.props.reviews.length}</strong>{" "}
                              reviews
                            </p>
                          </div>

                          {/* <p>{this.props.profile[0]?.city}</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ) : (
              <div></div>
            )}
            <div>
              <div
                className="col-md-12 col-sm-12 col-xs-12"
                style={{
                  backgroundColor: "#FFF",
                  paddingTop: 8,
                  paddingBottom: 8,
                }}
              >
                {this.props.authUser ? (
                  <div>
                    <Button
                      className="btn-lg add-new-post-btn"
                      onClick={this.handleShow}
                      style={{ width: "100%", marginBottom: 8 }}
                    >
                      <p style={{ color: "#FFFFFF" }}>
                        <i
                          className="fas fa-pencil-alt"
                          style={{ marginRight: "6px" }}
                        ></i>
                        <strong>Write a review</strong>
                      </p>
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button
                      className="btn-lg add-new-post-btn"
                      onClick={this.handleShow}
                      style={{ width: "100%", marginBottom: 8 }}
                    >
                      <p style={{ color: "#FFFFFF" }}>
                        <i
                          className="fas fa-pencil-alt"
                          style={{ marginRight: "6px" }}
                        ></i>
                        <strong>Write a review</strong>
                      </p>
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <ReviewsView />
            <div>
              {/* <LocalMap
                serviceObj={{
                  lat: this.props.profile[0].lat,
                  lng: this.props.profile[0].lng,
                }} */}
              {/* /> */}
            </div>
          </div>
        </div>

        {this.props.authUser ? (
          <div>
            <Modal
              {...this.props}
              show={this.state.show}
              onHide={this.handleHideReviewForm}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">
                  <h3> Review</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ backgroundColor: "#f5f5f7", width: "100%" }}>
                <ReviewForm
                  reviewBody={this.props.reviewBody}
                  onSubmit={(reviewBody) => {
                    this.props.startAddReview(slug, reviewBody);
                  }}
                />
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          <div>
            <Modal
              {...this.props}
              show={this.state.show}
              onHide={this.handleHide}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg"></Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ backgroundColor: "#f5f5f7", width: "100%" }}>
                <img src={sign_up_img_modal} style={{ width: "100%" }} />
                <Link to={routes.SIGN_UP}>
                  <Button
                    className="btn-lg add-new-post-btn"
                    onClick={this.handleShow}
                    style={{ width: "100%", marginBottom: 8 }}
                  >
                    <p style={{ color: "#FFFFFF" }}>
                      <strong>Sign Up</strong>
                    </p>
                  </Button>
                </Link>
                <Link to={routes.SIGN_IN}>
                  <Button
                    className="btn-lg transparent"
                    onClick={this.handleShow}
                    style={{ width: "100%", marginBottom: 8 }}
                  >
                    <div>
                      <strong>Sign in</strong>
                    </div>
                  </Button>
                </Link>
              </Modal.Body>
            </Modal>
          </div>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  reviews: state.reviews,
  profile: state.profile,
  authUser: state.sessionState.authUser,
  filters: state.filters.items,
  sort: state.sort.type,
});

const mapDispatchToProps = (dispatch, props) => ({
  startAddReview: (id, reviewBody) => dispatch(startAddReview(id, reviewBody)),
  fetchProducts: (filters, sort, products) =>
    dispatch(fetchProducts(filters, sort, products)),
  fetchReviews: (data) => dispatch(fetchReviews(data)),
  fetchUserProfileProps: (data) => dispatch(fetchUserProfileProps(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PublicProfile
);
// export default compose(connect(mapStateToProps, {startAddReview, fetchProducts, fetchReviews }))(PublicProfile);
