import firebase from 'firebase/compat/app';
import database  from '../firebase/firebase'


export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
export const EDIT_USER = 'EDIT_USER';
export const FETCH_USER_REVIEWS = 'FETCH_USER_REVIEWS';


// FETCH_USER CURRENTLY LOGGED IN
export const getUser = payload => ({
  type: 'FETCH_USER',
  payload
});

export const startUserProps = () => {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    return database.ref(`users/${user.uid}`).on('value', snapshot => {
      let payload = snapshot.val()
      dispatch(getUser(payload));
    })
  }
}

// FETCH_USER CURRENTLY LOGGED IN
export const getUserReviews = payload => ({
  type: 'FETCH_USER_REVIEWS',
  payload
});


export const startGetUserReviews = (uid) => {
  return async (dispatch) => {
    try {

      const snapshot = await firebase.firestore().collection('usersDB').doc(`${uid}`).collection('reviews').get()
      // console.log('::: ', snapshot.docs.map(doc => doc.data()))

      let user_reviews = [];
      
      
      snapshot.docs.forEach((doc) => {
        // console.log('doc::: ', doc.data());
        let reviewsData = doc.data();
        user_reviews.push(reviewsData);
      });

      console.log('reviews::: ', user_reviews);
      dispatch(getUserReviews(user_reviews));
    } catch (error) {
      console.log(error, "error");
    }
  };
};


// export const startGetUserReviews = (uid) => {
//   return (dispatch, getState) => {
    
//     const user = getState().auth.user;

//     console.log('uid::: ', uid);
//     return database.ref(`reviews`).on('value', snapshot => {
      
//       let payload = snapshot.val()

//       dispatch(getUser(payload));

//     })
//   }
// }


// EDIT_USER_PROPS
export function editUserDetails() {

  return dispatch => {

    firebase.auth().onAuthStateChanged((user) => {

      firebase.database().ref(`users/${user.uid}/`).on('value', snapshot => {

        let payload = snapshot.val()

        dispatch({
          type: FETCH_USER_DETAILS,
          payload
        })

      })
    })
  }
}


// EDIT_LIST
export const editUser = (updates) => ({
  type: EDIT_USER,
  updates
});

export const startEditUser = (updates) => {

  firebase.auth().onAuthStateChanged((user) => {
  return dispatch => {
    firebase.database().ref(`users/${user.uid}/`).update(updates).then(() => {
      dispatch(editUser(updates));
    });
  };
})

};

