import database from "../firebase/firebase";
import firebase from "firebase/compat/app";
import _ from "lodash";

/**
 |--------------------------------------------------
 | Types
 |--------------------------------------------------
 */

export const ADD_BUSINESS = "ADD_BUSINESS";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";

/**
 |--------------------------------------------------
 | Fetch lessons for signed in user
 |--------------------------------------------------
 */

export const SET_BUSINESS = "SET_BUSINESS";

export const setBusiness = (business) => ({
  type: SET_BUSINESS,
  business,
});

export const startSetDispensaries = (cityName) => {
  return (dispatch) => {
    let disRef = database.ref(`business`);

    disRef
      .orderByChild("city")
      .equalTo(cityName)
      .once("value")
      .then((snapshot) => {
        const business = [];

        snapshot.forEach((childSnapshot) => {
          business.push({
            id: childSnapshot.key,
            ...childSnapshot.val(),
          });
        });

        // console.log(business, "JOBS ACTION COMPLEX")
        dispatch(setBusiness(business));
      });
  };
};

// ADD_BUSINESS
export const addBusiness = (business) => ({
  type: ADD_BUSINESS,
  business,
});

export const startAddBusiness = (businessData = {}) => {
  return (dispatch) => {
    const {
      createdAt = 0,
      address = "",
      city = "",
      displayName = "",
      longitude = 0,
      latitude = 0,
      license_type = "",
      slug = "",
      website = "",
      type = "",
      id = "",
      uid = "",
      zip_code = "",
      phone_number = "",
      feature_order = 0,
      has_curbside_pickup = false,
      has_featured_deal = false,
      has_sale_items = false,
      is_published = true,
      menu_items_count = 0,
      enabled_for_delivery = false,
      after_hours_enabled = true,
      enabled_for_pickup = false,
      identification_required = true,
      is_orders_enabled = true,
      open_now = true,
      package_level = "free",
      photoURL = "https://firebasestorage.googleapis.com/v0/b/tierly-712dc.appspot.com/o/profile_images%2Fdefault_avatar.jpg?alt=media&token=2f96b498-bedf-410e-8558-08c3983ac0ee",
      ranking = 0,
      rating = 0,
      retailer_services = ["storefront", "pickup"],
      reviews_count = 0,
      state = "Oklahoma",
      timezone = "America/Chicago",
      todays_hours_str = "10:00am - 10:00pm",
      verified_menu_items_count = 0,
      map_featured = false,
      verified = false,
      lic_number = "",
    } = businessData;

    const business = {
      createdAt,
      phone_number,
      id,
      address,
      city,
      displayName,
      latitude,
      longitude,
      license_type,
      lic_number,
      slug,
      type,
      id,
      uid,
      zip_code,
      feature_order,
      has_curbside_pickup,
      has_featured_deal,
      has_sale_items,
      is_published,
      menu_items_count,
      enabled_for_delivery,
      after_hours_enabled,
      enabled_for_pickup,
      identification_required,
      is_orders_enabled,
      open_now,
      package_level,
      ranking,
      rating,
      retailer_services,
      reviews_count,
      state,
      timezone,
      todays_hours_str,
      verified_menu_items_count,
      website,
      photoURL,
      map_featured,
      verified,
    };

    // let updates = {};

    // updates[`/${type}/${id}`] = business;

    const db = firebase.firestore();

    db.collection("dispensariesDB")
      .doc(`${id}`)
      .set(business, { merge: true })
      .then(function (docRef) {
        dispatch(
          addBusiness({
            id: docRef,
            ...business,
          })
        );
        console.log("Business:::Added doc with this ID::: ", id);
        console.log("Document written with ID: ", docRef.store_name);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  };
};

// REMOVE_PRODUCT
export const removeProduct = ({ id } = {}) => ({
  type: REMOVE_PRODUCT,
  id,
});

export const startRemoveProduct = ({ id, slug } = {}) => {
  const { currentUser } = firebase.auth();
  // console.log(id, slug, "DELETE")
  return (dispatch) => {
    return database
      .ref(`users/${slug}/products/${id}`)
      .remove()
      .then(() => {
        dispatch(removeProduct({ id, slug }));
      })
      .then(() => {
        const followersRef = database.ref(`followers/${currentUser.uid}`);
        followersRef.on("value", (snapshot) => {
          const followers = Object.keys(snapshot.val());
          followers.forEach((key) =>
            database
              .ref(`/timeline/${key}/${id}`)
              .remove()
              .then(() => {
                dispatch(removeProduct({ id, slug }));
              })
          );
        });
      });
  };
};
