import React, { Component } from "react";
import { Grid, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import firebase from "firebase/compat/app";
import { Helmet } from "react-helmet";
import { fetchPublicProfileLikes } from "../../actions/likes";
import moment from "moment";
import Layout from "../layout/Layout";
import _ from "lodash";
import { MentionsInput, Mention } from "react-mentions";
import {
  fetchUserProfileLists,
  startRemovePost,
  startEditList,
} from "../../actions/posts";
import "../../styles/App.css";
import LikeButton from "./LikeButton";
import { Link } from "react-router-dom";
import CommentsView from "../comments/CommentsView";
import CommentsList from "../comments/CommentsList";
import ProfileSidebar from "../profile/ProfileSidebar";
import CommentsCounter from "../timeline/CommentsCounter";
import ReactPlayer from "react-player";
class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: false,
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user: true });
      }
    });
  }

  render() {
    const { photoURL, displayName } = this.props.profileData;
    const { title, createdAt, img_url, video, video_url } = this.props;

    return (
      <div>
        {video ? (
          <div
            style={{
              boder: "none",
              minWidth: 200,
              width: "100%",
              maxHeight: 1350,
              borderTopRightRadius: 22,
              borderTopLeftRadius: 22,
              overflow: "hidden",
            }}
          >
            <ReactPlayer
              playing={true}
              controls={true}
              // onReady={() => (profile[0] ? true : false)}
              paused={true}
              className="react-player"
              url={video_url}
              width="100%"
              height="100%"
            />
          </div>
        ) : (
          <div className="list-card-style">
            {img_url ? (
              <div>
                <img
                  src={img_url}
                  alt="post"
                  style={{
                    boder: "none",
                    width: "100%",
                    height: "100%",
                    borderTopRightRadius: 22,
                    borderTopLeftRadius: 22,
                  }}
                />
              </div>
            ) : (
              <div />
            )}

            <div className="list-card">
              <div
                className="list-profile-avatar-wrapper"
                style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                  paddingLeft: 16,
                  paddingRight: 8,
                }}
              >
                <Link to={{ pathname: `/u/${displayName}/` }}>
                  <img
                    src={photoURL}
                    className="list-profile-avatar"
                    alt="profile img"
                  />
                </Link>
              </div>

              <div className="list-detail-wrapper">
                <div>
                  <Link to={{ pathname: `/u/${displayName}/` }}>
                    <p className="list-user-name">{displayName}</p>
                  </Link>
                  <p className="list-title">{title}</p>
                  <p className="timestamp">{moment(createdAt).fromNow()}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

class ListDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: {},
      profileData: {},
      likes: [],
    };
  }

  getCommunityPosts = async () => {
    try {
      const postID = this.props.match.params.id;
      console.log("postID::: ", postID);
      const db = firebase.firestore().collection("postsDB");
      let communityArr = await db
        .doc("oklahoma")
        .collection("posts")
        .doc(`${postID}`)
        .get();

      let productsArrayTemp = [];
      communityArr.data();
      // communityArr.forEach((doc) => {
      //   let dispoData = doc.data();
      //   productsArrayTemp.push(dispoData);
      // });

      this.setState({ profileData: communityArr.data() });
      this.setState({ isLoading: false });

      this.setState({ posts: productsArrayTemp });
    } catch (error) {
      console.log(error, "error");
    }
  };
  componentDidMount() {
    this.getCommunityPosts();
  }

  removePostButton() {
    const loggedInUserUID = _.toPlainObject(this.props.authUser);
    const { uid } = this.state.profileData;
    return loggedInUserUID.uid === uid ? (
      <div
        onClick={() => {
          this.props.startRemovePost({ id: this.props.match.params.id });
          this.props.history.push("/");
        }}
      >
        <Button style={{ marginBottom: "10px" }}>
          {" "}
          <div className="remove-tierly-div-style">
            {" "}
            <i class="fa fa-trash" aria-hidden="true" /> Remove{" "}
          </div>{" "}
        </Button>
      </div>
    ) : (
      <div />
    );
  }

  shareTierlyButton() {
    const loggedInUserUID = _.toPlainObject(this.props.authUser);
    const { uid } = this.state.profileData;
    return loggedInUserUID.uid === uid ? (
      <div
        onClick={() => {
          this.props.startShareTierly({ id: this.props.match.params.id });
          this.props.history.push("/");
        }}
      >
        <Button style={{ marginBottom: "10px" }}>
          {" "}
          <div className="add-comment-div-style">
            <i class="fas fa-share" /> Share{" "}
          </div>{" "}
        </Button>
      </div>
    ) : (
      <div />
    );
  }

  render() {
    const { posts, uid, displayName } = this.state.profileData;
    const listArr = _.toArray(posts);
    const { id } = this.props.match.params;
    // const list = listArr.find((list) => list.id === id);
    const listObj = _.toPlainObject(this.state.profileData);
    const likes = listObj.likeCounts;

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title> {`${displayName} | CriticaPro`}</title>
        </Helmet>
        <Grid style={{ marginTop: 20 }}>
          <div>
            <Col md={3}>
              <ProfileSidebar profileData={this.state.profileData} />
            </Col>

            <Col md={6} sm={12} xs={12}>
              <List
                {...this.state.profileData}
                profileData={this.state.profileData}
                userProps={this.props.userProps}
                id={id}
              />

              <div className="likes_share_comment_container">
                <div style={{ justifyContent: "center", alignItems: "center" }}>
                  {this.props.authUser ? (
                    <LikeButton
                      postId={id}
                      postUID={this.state.profileData.uid}
                      postLikes={likes}
                    />
                  ) : (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <span>
                            <i
                              className="fa fa-heart"
                              aria-hidden="true"
                              style={{ color: "#CFD8DC", fontSize: 24 }}
                            />
                          </span>
                        </div>
                        <div style={{ marginLeft: 6 }}>
                          <p
                            style={{
                              color: "#CFD8DC",
                              fontWeight: "bold",
                              fontSize: 14,
                            }}
                          >
                            {likes}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div style={{ justifyContent: "center" }}>
                  <CommentsCounter id={this.props.match.params.id} />
                </div>

                <div style={{ justifyContent: "center", alignItems: "center" }}>
                  <div
                    onClick={() =>
                      navigator.clipboard
                        .writeText(
                          `https://criticapro.com/u/${this.props.match.params.uid}/posts/${this.props.match.params.id}`
                        )
                        .then(() => alert("Link copied"))
                    }
                  >
                    <i
                      active
                      className="fas fa-share"
                      aria-hidden="true"
                      style={{ color: "#CFD8DC", fontSize: 24 }}
                    />
                  </div>
                </div>
              </div>

              {/* {this.props.authUser ? (
                <CommentsView id={this.props.match.params.id} />
              ) : (
                <div />
              )} */}

              {/* <CommentsList
                profileData={this.state.profileData}
                id={this.props.match.params.id}
              /> */}
            </Col>
            {this.removePostButton()}
          </div>
        </Grid>
      </Layout>
    );
  }
}

const mapStateToProps = (state, props) => ({
  authUser: state.sessionState.authUser,
  posts: state.posts,
  userProps: state.userProps,
  likes: state.likes,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserProfileLists: (uid) => dispatch(fetchUserProfileLists(uid)),
  startRemovePost: (data) => dispatch(startRemovePost(data)),
  fetchPublicProfileLikes: (uid, id) =>
    dispatch(fetchPublicProfileLikes(uid, id)),
  startEditList: () => dispatch(startEditList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListDetail);
