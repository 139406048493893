import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";
// import { getAuth } from 'firebase/auth'
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/storage";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";

const prodConfig = {
  apiKey: "AIzaSyAXapDoZ0Z_GbKfZGaLbshKJoiH_tmJBAs",
  authDomain: "criticapro-262d3.firebaseapp.com",
  databaseURL: "https://criticapro-262d3-default-rtdb.firebaseio.com",
  projectId: "criticapro-262d3",
  storageBucket: "criticapro-262d3.appspot.com",
  messagingSenderId: "679746663817",
  appId: "1:679746663817:web:b9355df00e407ac19f9d58",
  measurementId: "G-J6VKV8351S",
};

const devConfig = {
  apiKey: "AIzaSyAXapDoZ0Z_GbKfZGaLbshKJoiH_tmJBAs",
  authDomain: "criticapro-262d3.firebaseapp.com",
  databaseURL: "https://criticapro-262d3-default-rtdb.firebaseio.com",
  projectId: "criticapro-262d3",
  storageBucket: "criticapro-262d3.appspot.com",
  messagingSenderId: "679746663817",
  appId: "1:679746663817:web:b9355df00e407ac19f9d58",
  measurementId: "G-J6VKV8351S",
};

const config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;

if (!firebase.apps.length) {
  // const app = initializeApp(config);
  firebase.initializeApp(config);
}

// const app = initializeApp(config);
// const dbstore = getFirestore(config);
const database = firebase.database();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const db = firebase.database();
const auth = firebase.auth();
const storage = firebase.storage();

export {
  db,
  auth,
  storage,
  firebase,
  googleAuthProvider,
  // dbstore,
  database as default,
};

// import * as firebase from 'firebase';

// const config = {
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.FIREBASE_DATABASE_URL,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID
// };

// firebase.initializeApp(config);

// const database = firebase.database();
// const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

// export { firebase, googleAuthProvider, database as default };
