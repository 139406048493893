import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "recompose";
// import ListIndexFilters from "./ListIndexFilters";
import { startSetDispensaries } from "../../actions/dispensaries";
import itemSelector from "./../../selectors/selector";
// import div from "../layout/div";
import GoogleMap from "google-map-react";
import _ from "lodash";
import { fetchReviews, startAddReview } from "../../actions/reviews";
import { fetchUserProfileProps } from "../../actions/profile";
// import map_marker from '../../assets/images/map_marker.png'

import { Col } from "react-bootstrap";
// import HeroHeader from '../landing/HeroHeader';
// import dispensaries from "../../reducers/dispensaries";
import MapSmallCard from "./MapSmallCard";
import LoadingPage from "../utilities/LoadingPage";
import Navigation from "../layout/Navigation";
import Footer from "../layout/Footer";
import ReviewsView from "../reviews/ReviewsView";
import defaultPhotoURL from "../../assets/images/default_avatar.jpg";
// const K_MARGIN_TOP = 30;
// const K_MARGIN_RIGHT = 30;
// const K_MARGIN_BOTTOM = 30;
// const K_MARGIN_LEFT = 30;
const K_HOVER_DISTANCE = 100 / 2;

// const bounds = {
//   nw: {
//     lat: 50.01038826014866,
//     lng: -118.6525866875
//   },
//   se: {
//     lat: 32.698335045970396,
//     lng: -92.0217273125
//   }
// };

// Or
// const K_SCALE_NORMAL = 0.65;

// const bounds = {
//   ne: {
//     lat: 36.5312376,
//     lng: -96.6935472
//   },
//   sw: {
//     lat: 34.230039,
//     lng: -98.800048
//   }
// };

// const size = {
//   width: 640, // Map width in pixels
//   height: 380, // Map height in pixels
// };

// const {center, zoom} = fitBounds(bounds, size);

class ExploreDispensaries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: {},
      displayName: "",
      city_name: "",
      user_search: null,
      zoomToMarkers: null,
      zoom: null,
      center: {},
      address: "",
      latLng: {},
      addressSet: false,
      show: false,
      slug: null,
      showAddress: false,
    };
    this.handleHideReviewForm = this.handleHideReviewForm.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleHideReviewForm() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  componentDidMount() {
    if (_.toArray(this.props.serviceObj).length === 0) {
      this.getLocation();
    } else {
      this.setState({ center: this.props.serviceObj });
    }
  }

  _onBoundsChange = (center, zoom, bounds, marginBounds) => {
    if (this.props.onBoundsChange) {
      this.props.onBoundsChange({ center, zoom, bounds, marginBounds });
    } else {
      this._onChange(center);
    }
  };

  _onChange({ center, zoom, bounds, ...other }) {
    // unstable_batchedUpdates(() => {
    //  this.props.onZoomChange(zoom);
    // change center here
    this.setState({ zoom: zoom });
    // });
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // console.log(position, "Position")
        this.setState({ center: pos });
        console.log("pos::: ", pos);
      });
    } else {
      this.setState({ err: "Geolocation is not supported by this browser." });
    }
  }

  render() {
    const serviceArr = this.props.serviceObj;
    console.log("this.state.center::: ", this.state.center);
    return (
      <div>
        <div>
          <div>
            {serviceArr === null ? (
              <LoadingPage />
            ) : (
              <div>
                <div
                  style={{
                    height: 300,
                    width: "100%",
                    position: "absolute",
                  }}
                >
                  <GoogleMap
                    bootstrapURLKeys={{
                      key: "AIzaSyAXapDoZ0Z_GbKfZGaLbshKJoiH_tmJBAs",
                    }}
                    defaultCenter={this.state.center}
                    center={this.state.center}
                    zoom={15}
                    onBoundsChange={this._onBoundsChange}
                    hoverDistance={K_HOVER_DISTANCE}
                  >
                    <MapSmallCard
                      // key={1}
                      // text={""}
                      // hover={this.props.hoverKey === 1}
                      lat={this.props.serviceObj.lat}
                      lng={this.props.serviceObj.lng}
                    />
                  </GoogleMap>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    profile: state.profile,
    // userProps: state.userProps,
    dispensaries: itemSelector(state.dispensaries, state.filtersText),
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  startAddReview: (id, reviewBody) => dispatch(startAddReview(id, reviewBody)),
  // fetchProducts: (filters, sort, products) => dispatch(fetchProducts(filters, sort, products)),
  fetchReviews: (data) => dispatch(fetchReviews(data)),
  fetchUserProfileProps: (data) => dispatch(fetchUserProfileProps(data)),
  startSetDispensaries: (data) => dispatch(startSetDispensaries(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ExploreDispensaries
);
