import database from "../firebase/firebase";
import firebase from "firebase/compat/app";

/**
 |--------------------------------------------------
 | Types
 |--------------------------------------------------
 */
export const SET_REVIEWS = "SET_REVIEWS";

export const ADD_REVIEW = "ADD_REVIEW";

export const REMOVE_REVIEW = "REMOVE_REVIEW";

/**
|--------------------------------------------------
| Actions
|--------------------------------------------------
*/
//${currentUser.uid}

// export function fetchReviews( id ) {
//   return dispatch => {
//     database.ref(`/reviews/${id}/`).on('value', snapshot => {
//       dispatch({
//         type: SET_REVIEWS,
//         payload: snapshot.val()
//       })
//     })
//   }
// }

// FETCH_USER CURRENTLY LOGGED IN
export const getReviews = (reviews) => ({
  type: SET_REVIEWS,
  reviews,
});

export const fetchReviews = (slug) => {
  return async (dispatch) => {
    try {
      const snapshot = await firebase
        .firestore()
        .collection("service-reviews")
        .doc(`${slug}`)
        .collection("reviews")
        .get();

      let reviews = [];

      snapshot.forEach((doc) => {
        let dreviewsData = doc.data();
        reviews.push(dreviewsData);
      });

      dispatch(getReviews(reviews));
    } catch (error) {
      console.log(error, "error");
    }
  };
};

// ADD_REVIEW
export const addReview = (slug, reviewBody) => ({
  type: ADD_REVIEW,
  slug,
  reviewBody,
});

export const startAddReview = (slug, reviewData = {}) => {
  return (dispatch) => {
    const db = firebase.firestore();

    const newReviewKey = firebase
      .database()
      .ref(`reviews/${slug}`)
      .child("review")
      .push().key;

    const {
      address = "",
      place_id = "",
      review_body = "",
      companyUID = "",
      reason = "",
      createdAt = 0,
      likes = 0,
      reviewId = newReviewKey,
      photoURL = firebase.auth().currentUser.photoURL,
      uid = firebase.auth().currentUser.uid,
      displayName = firebase.auth().currentUser.displayName,
      company = "",
      rating = 0,
      img_url = [],
      id = "",
      lat = 0,
      lng = 0,
    } = reviewData;

    const reviewBody = {
      companyUID,
      company,
      displayName,
      uid,
      place_id,
      reason,
      slug,
      photoURL,
      review_body,
      createdAt,
      likes,
      reviewId,
      rating,
      img_url,
      id,
      lat,
      lng,
      address,
    };

    const reviewLocation = {
      slug,
      lat,
      lng,
      address,
      place_id,
      rating,
    };

    db.collection("service-reviews")
      .doc(`${slug}`)
      .collection("reviews")
      .doc(`${reviewId}`)
      .set(reviewBody, { merge: true });

    db.collection("reviewsDB")
      .doc(`${slug}`)
      .set(reviewLocation, { merge: true });

    db.collection("usersDB")
      .doc(`${uid}`)
      .collection("reviews")
      .doc(`${reviewId}`)
      .set(reviewBody, { merge: true });

    let updates = {};
    updates[`/reviews/${slug}/${newReviewKey}`] = reviewBody;
    // updates[`/users/${uid}/reviews/${newReviewKey}`] = reviewBody;

    return database
      .ref()
      .update(updates)
      .then((ref) => {
        dispatch(
          addReview({
            id: ref.key,
            ...reviewBody,
          })
        );
      });
  };
};

export const removeReview = ({ reviewId, uid } = {}) => ({
  type: REMOVE_REVIEW,
  reviewId,
  uid,
});

export const startRemoveReview = ({ reviewId, slug, uid } = {}) => {
  return (dispatch) => {
    const db = firebase.firestore();

    //Create analytics/impression node for post
    // db.collection("analytics")
    //   .doc(`${uid}`)
    //   .collection("product")
    //   .doc(`${id}`)
    //   .delete();
    //Create post in users product node
    db.collection("service-reviews")
      .doc(`${slug}`)
      .collection("reviews")
      .doc(`${reviewId}`)
      .delete();

    db.collection("usersDB")
      .doc(`${uid}`)
      .collection("reviews")
      .doc(`${reviewId}`)
      .delete()
      .then(function (docRef) {
        console.log("city:::Added doc with this ID::: ", reviewId);
        console.log("Document written with ID: ", docRef.reviewId);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });

    return database
      .ref(`reviews/${slug}/${reviewId}`)
      .remove()
      .then(() => {
        dispatch(removeReview({ reviewId, uid }));
      })
      .catch((e) => console.log("e::: ", e));
  };
};
