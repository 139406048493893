import database from "../firebase/firebase";
import firebase from "firebase/compat/app";

/**
 |--------------------------------------------------
 | Types
 |--------------------------------------------------
 */
export const SET_REVIEWS_LOCATIONS = "SET_REVIEWS_LOCATIONS";

// export const ADD_REVIEW = "ADD_REVIEW";

// export const REMOVE_REVIEW = "REMOVE_REVIEW";

/**
|--------------------------------------------------
| Actions
|--------------------------------------------------
*/
//${currentUser.uid}

// export function fetchReviews( id ) {
//   return dispatch => {
//     database.ref(`/reviews/${id}/`).on('value', snapshot => {
//       dispatch({
//         type: SET_REVIEWS_LOCATIONS,
//         payload: snapshot.val()
//       })
//     })
//   }
// }


// FETCH_USER CURRENTLY LOGGED IN
export const getReviews = (reviews_locations) => ({
  type: SET_REVIEWS_LOCATIONS,
  reviews_locations,
});

export const fetchLocalReviews = () => {
  return async (dispatch) => {
    try {

      const snapshot = await firebase.firestore().collection('reviewsDB').get()

      let reviews_locations = [];
      
      
      snapshot.docs.forEach((doc) => {
        let reviewsData = doc.data();
        reviews_locations.push(reviewsData);
      });

      dispatch(getReviews(reviews_locations));
    } catch (error) {
      console.log(error, "error");
    }
  };
};
