import React, { Component } from "react";
import { Grid } from "react-bootstrap";
import Layout from "../layout/Layout";

export default class TermsAndPrivacy extends Component {
  render() {
    return (
      <Layout>
        <Grid style={{ marginTop: 32, marginBottom: 32 }}>
          <div style={{ margin: 16, paddingTop: 16 }}>
            <p style={{ fontSize: 48, fontWeight: "900", color: "#212121" }}>
              About
            </p>
            <p style={{ fontWeight: "700" }}>Why we do what we do.</p>
          </div>

          <div
            style={{
              marginLeft: 16,
              marginRight: 16,
              marginTop: 16,
              borderRadius: 16,
              elevation: 1,
            }}
          >
            <div style={styles.title_container}>
              <p style={styles.title}>Welcome to CriticaPro</p>
            </div>

            <div style={styles.title_container}>
              <p>
                A small startup in the cannabis industry dedicated to promoting
                natural medical solutions, respecting patients' free choices,
                and supporting users' freedom of expression. We believe in the
                power of cannabis as a transformative plant with incredible
                potential for holistic well-being.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                At CriticaPro, we prioritize the well-being and freedom of
                individuals seeking natural medical alternatives. We understand
                that every person's journey is unique, and we strive to provide
                a platform that supports their choices and empowers them to make
                informed decisions about their health.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                Our mission is to create a community that celebrates the
                therapeutic benefits of cannabis while fostering an environment
                of respect and inclusivity. We firmly believe in the power of
                cannabis to alleviate symptoms, promote healing, and enhance
                overall quality of life.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                We are committed to sourcing and providing high-quality, natural
                cannabis products that meet the diverse needs of our customers.
                Our dedication to quality ensures that each product we offer
                undergoes rigorous testing and meets stringent standards to
                guarantee purity, potency, and safety.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                Beyond providing top-notch cannabis products, we believe in the
                power of education. We strive to empower our community by
                providing accurate and up-to-date information about cannabis,
                its various applications, and responsible consumption practices.
                Through educational resources and engaging content, we aim to
                foster a deeper understanding of cannabis as a natural medical
                solution.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                At CriticaPro, we celebrate and support the freedom of
                expression within the cannabis community. We believe in the
                importance of creating a space where individuals can openly
                express their thoughts, experiences, and creativity. We
                encourage our users to share their stories, connect with
                like-minded individuals, and contribute to the ongoing dialogue
                surrounding medical cannabis.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                As we embark on this journey, we are grateful for the
                opportunity to serve and support our community here in Oklahoma.
                We are dedicated to providing exceptional customer service,
                building trust, and establishing lasting relationships with our
                customers. Your satisfaction and well-being are at the core of
                everything we do.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                Join us in embracing the power of natural medical solutions,
                respecting individual choices, and celebrating the freedom of
                expression within the cannabis industry. Together, we can create
                a brighter and healthier future through the incredible potential
                of cannabis.
              </p>
            </div>
            <div style={styles.title_container}>
              <p>
                Thank you for being a part of the CriticaPro experience. We're
                honored to be part of your journey towards wellness and personal
                growth in a natural manner.
              </p>
            </div>
          </div>
        </Grid>
      </Layout>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    paddingTop: 90,
  },
  detailsContainer: {
    padding: 16,
    margin: 10,
    backgroundColor: "#ffffff",
    borderRadius: 10,
    shadowColor: "#AAAAAA",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 5,
  },
  title_container: {
    paddingBottom: 5,
    marginBottom: 12,
  },
  title: {
    fontWeight: "bold",
  },
};
