// Dispensaries Reducer

import { SET_SERVICE_DETAILS } from "../actions/service_address";

const serviceAddressReducerDefaultState = {};

export default (state = serviceAddressReducerDefaultState, action) => {
  switch (action.type) {
    case SET_SERVICE_DETAILS:
      return action.details;

    // case ADD_JOB:
    // return [...state, action.service_address];
    default:
      return state;
  }
};
