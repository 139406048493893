import React, { useState, Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { Helmet } from "react-helmet";
import "../../styles/App.css";
import Layout from "../layout/Layout";
import { Modal, Button } from "react-bootstrap";
import DownloadAppFullWidthCTA from "./DownloadAppFullWidthCTA";

const HeaderTags = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>CriticaPro - Connect With The Medical Cannabis Community</title>
    <link rel="canonical" href="https://criticapro.com/" />
  </Helmet>
);

class Download extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isMounted: false,
      isEmpty: false,
      isFinished: false,
      landingData: {},
      isChecked: false,
      show: false,
    };
  }
  state = {
    isLoading: false,
    landingData: {},
  };

  render() {
    return (
      <Layout>
        <HeaderTags />
        <DownloadAppFullWidthCTA />
      </Layout>
    );
  }
}
const mapStateToProps = (state) => ({
  recent_news: state.recent_news,
});

export default Download;
