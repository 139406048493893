import database from "../firebase/firebase";
import firebase from "firebase/compat/app";
import _ from "lodash";

/**
 |--------------------------------------------------
 | Types
 |--------------------------------------------------
 */

export const ADD_BUSINESS = "ADD_BUSINESS";
export const SET_BUSINESS = "SET_BUSINESS";

const business_dir = "growers";
export const setBusiness = (business) => ({
  type: SET_BUSINESS,
  business,
});

export const startSetBusiness = (cityName) => {
  return (dispatch) => {
    let disRef = database.ref(`${business_dir}`);

    disRef
      .orderByChild("city")
      .equalTo(cityName)
      .once("value")
      .then((snapshot) => {
        const business = [];

        snapshot.forEach((childSnapshot) => {
          business.push({
            id: childSnapshot.key,
            ...childSnapshot.val(),
          });
        });

        // console.log(business, "JOBS ACTION COMPLEX")
        dispatch(setBusiness(business));
      });
  };
};

/**
 |--------------------------------------------------
 | Fetch lessons for signed in user
 |--------------------------------------------------
 */

// ADD_BUSINESS
export const addBusiness = (business) => ({
  type: ADD_BUSINESS,
  business,
});

export const startAddBusiness = (businessData = {}) => {
  return (dispatch) => {
    const {
      createdAt = 0,
      address = "",
      city = "",
      displayName = "",
      longitude = 0,
      latitude = 0,
      license_type = "",
      slug = "",
      website = "",
      type = "",
      id = "",
      uid = "",
      zip_code = "",
      feature_order = 0,
      has_curbside_pickup = false,
      has_featured_deal = false,
      has_sale_items = false,
      is_published = true,
      menu_items_count = 0,
      enabled_for_delivery = false,
      after_hours_enabled = true,
      enabled_for_pickup = false,
      identification_required = true,
      is_orders_enabled = true,
      open_now = true,
      package_level = "free",
      photoURL = "https://firebasestorage.googleapis.com/v0/b/tierly-712dc.appspot.com/o/profile_images%2Fdefault_avatar.jpg?alt=media&token=2f96b498-bedf-410e-8558-08c3983ac0ee",
      ranking = 0,
      rating = 0,
      retailer_services = ["storefront", "pickup"],
      reviews_count = 0,
      state = "Oklahoma",
      timezone = "America/Chicago",
      todays_hours_str = "10:00am - 10:00pm",
      verified_menu_items_count = 0,
    } = businessData;

    const business = {
      createdAt,
      id,
      address,
      city,
      displayName,
      latitude,
      longitude,
      license_type,
      slug,
      type,
      id,
      uid,
      zip_code,
      feature_order,
      has_curbside_pickup,
      has_featured_deal,
      has_sale_items,
      is_published,
      menu_items_count,
      enabled_for_delivery,
      after_hours_enabled,
      enabled_for_pickup,
      identification_required,
      is_orders_enabled,
      open_now,
      package_level,
      ranking,
      rating,
      retailer_services,
      reviews_count,
      state,
      timezone,
      todays_hours_str,
      verified_menu_items_count,
      website,
      photoURL,
    };

    let updates = {};

    updates[`/${type}/${id}`] = business;

    return database
      .ref()
      .update(updates)
      .then((ref) => {
        dispatch(
          addBusiness({
            id: ref,
            ...business,
          })
        );
      });
  };
};
