import database from "../firebase/firebase";
import firebase from "firebase/compat/app";
import _ from "lodash";

// const profile = 'https://firebasestorage.googleapis.com/v0/b/video-app-fdc27.appspot.com/o/profile_images%2FQcroUjlQ16PvL2rUzmFKvBKCfuH3%2Fjessie_small.jpg?alt=media&token=58f28991-79da-493a-8c61-bd3af5baa238'
/**
 |--------------------------------------------------
 | Types
 |--------------------------------------------------
 */

export const SET_PRODUCTS = "SET_PRODUCTS";
export const FETCH_TIMELINE_PRODUCTS = "FETCH_TIMELINE_PRODUCTS";
export const FETCH_PROFILE_PRODUCTS = "FETCH_PROFILE_PRODUCTS";
export const FETCH_MORE_PRODUCTS = "FETCH_MORE_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";

/**
 |--------------------------------------------------
 | Fetch lessons for signed in user
 |--------------------------------------------------
 */

// SET LISTS
export const setLists = (lessons) => ({
  type: FETCH_TIMELINE_PRODUCTS,
  lessons,
});

export const fetchLessons = () => {
  return (dispatch, getState) => {
    // const user = getState().auth.user;
    return database
      .ref(`lessons`)
      .once("value")
      .then((snapshot) => {
        const lessons = [];

        snapshot.forEach((childSnapshot) => {
          lessons.push({
            id: childSnapshot.key,
            ...childSnapshot.val(),
          });
        });
        dispatch(setLists(_.reverse(lessons)));
      });
  };
};

/**
 |--------------------------------------------------
 | Fetch lessons for signed in user
 |--------------------------------------------------
 */

// GETS FRIEND OR PUBLIC PROFILE BY PASSING USERS UID // USED by publicProfile
export function fetchUserProfileLists(uid) {
  return (dispatch) => {
    firebase
      .database()
      .ref(`users/${uid}/`)
      .on("value", (snapshot) => {
        let payload = snapshot.val();
        dispatch({
          type: FETCH_PROFILE_PRODUCTS,
          payload,
        });
      });
  };
}

// ADD_PRODUCT
export const addProduct = (product) => ({
  type: ADD_PRODUCT,
  product,
});

export const startAddProduct = (productData = {}) => {
  const { currentUser } = firebase.auth();
  return (dispatch) => {
    const {
      featured = false,
      createdAt = 0,
      currencyFormat = "$",
      currencyId = "USD",
      displayName = firebase.auth().currentUser.displayName,
      hidden = false,
      img_url = "",
      price = 0,
      productCategories = [],
      photoURL = firebase.auth().currentUser.photoURL,
      productNotes = "",
      productName = "",
      productThc = "",
      productTerps = "",
      type = "",
      product_type = "",
      manufacturer = "",
      // tags = [],
      priceRange = "",
      id = "",
      uid = firebase.auth().currentUser.uid,
      file = [],
      one_gram = 0,
      one_eight = 0,
      one_quarter = 0,
      one_half = 0,
      one_ounce = 0,
      slug = "",
      weights = "",
      productPostType = true,
      city = "",
      productCbd = "",
    } = productData;

    const product = {
      featured,
      currencyFormat,
      manufacturer,
      productCbd,
      productTerps,
      productPostType,
      hidden,
      img_url,
      price,
      productCategories,
      currencyId,
      displayName,
      photoURL,
      productName,
      type,
      product_type,
      productThc,
      productNotes,
      priceRange,
      weights,
      one_gram,
      one_eight,
      one_quarter,
      one_half,
      one_ounce,
      createdAt,
      id,
      uid,
      file,
      slug,
      city,
    };

    let updates = {};

    updates[`/dispensaries/${city}/${slug}/products/${id}`] = product;
    updates[`/products/${city}/${slug}/products/${id}`] = product;
    // updates[`/users/${currentUser.uid}/products/${id}`] = product;

    const db = firebase.firestore();

    let data = {
      id: id,
      impressions: 0,
      likes: 0,
      views: 0,
    };

    const lowerCaseCityName = _.toLower(city);

    //Create analytics/impression node for post
    db.collection("analytics")
      .doc(`${uid}`)
      .collection("product")
      .doc(`${id}`)
      .set(data, { merge: true });
    //Create post in users product node

    db.collection("usersDB")
      .doc(`${uid}`)
      .collection("product")
      .doc(`${id}`)
      .set(product, { merge: true });

    db.collection(`${lowerCaseCityName}`)
      .doc(`${id}`)
      .set(product, { merge: true })
      .then(function (docRef) {
        console.log("city:::Added doc with this ID::: ", id);
        console.log("Document written with ID: ", docRef.id);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });

    return database
      .ref()
      .update(updates)
      .then((ref) => {
        dispatch(
          addProduct({
            id: ref,
            ...product,
          })
        );
      });
  };
};

// REMOVE_PRODUCT
export const removeProduct = ({ id, uid } = {}) => ({
  type: REMOVE_PRODUCT,
  id,
  uid,
});

export const startRemoveProduct = ({ id, city, uid } = {}) => {
  return (dispatch) => {
    const db = firebase.firestore();
    const lowerCaseCityName = _.toLower(city);

    //Create analytics/impression node for post
    db.collection("analytics")
      .doc(`${uid}`)
      .collection("product")
      .doc(`${id}`)
      .delete();
    //Create post in users product node
    db.collection("usersDB")
      .doc(`${uid}`)
      .collection("product")
      .doc(`${id}`)
      .delete();
    db.collection(`${lowerCaseCityName}`)
      .doc(`${id}`)
      .delete()
      .then(function (docRef) {
        console.log("city:::Added doc with this ID::: ", id);
        console.log("Document written with ID: ", docRef.id);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });

    return database
      .ref(`users/${uid}/products/${id}`)
      .remove()
      .then(() => {
        dispatch(removeProduct({ id, uid }));
      })
      .then(() => {
        const followersRef = database.ref(`followers/${uid}`);

        followersRef.once("value").then((snapshot) => {
          const followers = Object.keys(snapshot.val());

          followers.forEach((key) =>
            database
              .ref(`/timeline/${key}/${id}`)
              .remove()
              .then(() => {
                dispatch(removeProduct({ id, uid }));
              })
          );
        });
      });
  };
};

// EDIT_PRODUCT
export const editProduct = (id, slug, updates) => ({
  type: EDIT_PRODUCT,
  id,
  updates,
  slug,
});

export const startEditProduct = (id, city, postData) => {
  const { currentUser } = firebase.auth();
  return (dispatch) => {
    const lowerCaseCityName = _.toLower(city);
    setTimeout(() => {
      firebase
        .firestore()
        .collection(lowerCaseCityName)
        .doc(`${id}`)
        .set(postData, { merge: true });
    }, 500);

    setTimeout(() => {
      firebase
        .firestore()
        .collection("usersDB")
        .doc(`${currentUser.uid}`)
        .collection("product")
        .doc(`${id}`)
        .set(postData, { merge: true });
    }, 500);

    // setTimeout(() => {
    //   firebase
    //     .firestore()
    //     .collection("timeslinesDB")
    //     .doc("timelines")
    //     .collection(`${currentUser.uid}`)
    //     .doc(`${id}`)
    //     .set(postData, { merge: true });
    // }, 500);

    setTimeout(() => {
      const followersRef = firebase
        .database()
        .ref(`followers/${currentUser.uid}`);
      followersRef
        .once("value")
        .then((snapshot) => {
          const followers = Object.keys(snapshot.val());

          followers.forEach((key) =>
            firebase
              .firestore()
              .collection("timeslinesDB")
              .doc("timelines")
              .collection(`${key}`)
              .doc(`${id}`)
              .set(postData, { merge: true })
          );
        })
        .then(() => {
          dispatch(editProduct(id, postData));
        });
    }, 500);
  };
};
