import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import Layout from "../layout/Layout";
import moment from "moment";
import { fetchRecentNews } from "../../actions/recent_news";
import _ from "lodash";
import { Helmet } from "react-helmet";
import "../Shelf/style.scss";
import { Link } from "react-router-dom";

const HeaderTags = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>Blog - CriticaPro</title>
    <link rel="canonical" href="https://criticapro.com/blog" />
  </Helmet>
);

class PublicProfile extends Component {
  componentDidMount() {
    this.props.fetchRecentNews();
  }

  render() {
    const postsARR = this.props.recent_news;
    return (
      <Layout>
        <HeaderTags />
        <div className="container-fluid" style={{ marginTop: 90 }}>
          <div className="app-wrapper" style={{ height: "100vh" }}>
            <div className="col-md-12 col-sm-12 col-xs-12">
              {/* <ProfileSidebar profileData={listObj}/> */}
            </div>

            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="col-md-2 col-sm-12 col-xs-12"></div>

              <div className="col-md-8 col-sm-12 col-xs-12">
                <div>
                  <h1
                    style={{
                      fontWeight: "bold",
                      fontSize: 28,
                      textTransform: "uppercase",
                      lineHeight: 0.5,
                    }}
                  >
                    Blog
                  </h1>
                </div>
                {postsARR.length === 0 ? (
                  <h2>
                    loading...
                    <span role="img" aria-label="sad emoji">
                      🐣
                    </span>
                  </h2>
                ) : (
                  postsARR.map((post) => (
                    <Link to={`/blog/${post.slug}/`} key={post.id}>
                      <div className="list-card-style">
                        {post.img_url ? (
                          <div>
                            <img
                              src={post.img_url}
                              alt="post"
                              style={{
                                boder: "none",
                                width: "100%",
                                height: "100%",
                                borderTopRightRadius: 22,
                                borderTopLeftRadius: 22,
                              }}
                            />
                          </div>
                        ) : (
                          <div />
                        )}

                        <div className="list-card">
                          <div className="list-detail-wrapper">
                            <div>
                              <p className="list-user-name">{post.title}</p>
                              {/* <p className="list-title">{post.title}</p> */}
                              <p className="timestamp">
                                {moment(post.createdAt).fromNow()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                )}
              </div>
              <div className="col-md-2 col-sm-12 col-xs-12"></div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  authUser: state.sessionState.authUser,
  recent_news: state.recent_news,
});

export default compose(connect(mapStateToProps, { fetchRecentNews }))(
  PublicProfile
);
