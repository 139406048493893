import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "recompose";
import { fetchReviews, startAddReview } from "../../actions/reviews";
import { startSetServiceDetails } from "../../actions/service_address";
import {
  greatPlaceStyle,
  smallCard,
} from "./my_great_place_with_hover_styles.js";
import map_marker from "../../assets/images/map_marker@2x.png";
import { useSelector, useDispatch } from "react-redux";

class MyGreatPlaceWithHover extends Component {
  static defaultProps = {};

  render() {
    const marker = this.props.$hover ? (
      <Card
        text={this.props.text}
        img_url={this.props.img_url}
        fetchReviews={this.props.fetchReviews}
        startSetServiceDetails={this.props.startSetServiceDetails}
      />
    ) : (
      <MarkerIcon />
    );

    return <div>{marker}</div>;
  }
}

const MarkerIcon = ({ text }) => (
  <div>
    <img
      src={map_marker}
      style={{ width: 40, height: 40 }}
      alt="map marker img"
    />
  </div>
);

const Card = (props) => {
  var star_rating = (rating) => {
    switch (rating) {
      case 1:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#f44336" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
          </span>
        );
      case 2:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#f44336" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#f44336" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
          </span>
        );
      case 3:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#FBC02D" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#FBC02D" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#FBC02D" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
          </span>
        );
      case 4:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
          </span>
        );
      case 5:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
          </span>
        );
      default:
        return <span> No Rating</span>;
    }
  };

  return (
    <div
      onClick={() => {
        props.text.img_url = props.img_url;
        props.fetchReviews(props.text.place_id);
        props.startSetServiceDetails([props.text]);
      }}
    >
      <div style={greatPlaceStyle}>
        <div style={smallCard}>
          <div className="list-profile-avatar-wrapper">
            <img
              src={`${`https://maps.googleapis.com/maps/api/streetview?size=100x100&location=${props.text.lat},${props.text.lng}&fov=80&heading=-120&pitch=10&key=AIzaSyAXapDoZ0Z_GbKfZGaLbshKJoiH_tmJBAs`}`}
              className="list-profile-avatar"
              alt="profile img"
            />
          </div>

          <div className="flex-parent">
            <strong className="long-and-truncated">{props.text.address}</strong>
            <div>
              <p className="comment-text">{star_rating(props.text.rating)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    authUser: state.sessionState.authUser,
    userProps: state.userProps,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  fetchReviews: (data) => dispatch(fetchReviews(data)),
  startSetServiceDetails: (data) => dispatch(startSetServiceDetails(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MyGreatPlaceWithHover
);
