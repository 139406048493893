import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Grid,
  Col,
  FormGroup,
  FormControl,
  Modal,
  Button,
} from "react-bootstrap";
import { compose } from "recompose";
import ListIndexFilters from "./ListIndexFilters";
import { startSetJobs } from "../../actions/jobs";
import itemSelector from "./../../selectors/selector";
import GoogleMapReact from "google-map-react";
import _ from "lodash";
import Layout from "../layout/Layout";
import logo from "../../assets/images/critica_logo_color_dark.svg";
import MapSmallCard from "./MapSmallCard";
import { fetchReviews, startAddReview } from "../../actions/reviews";
import { fetchLocalReviews } from "../../actions/reviews_locations";
import LoadingPage from "../utilities/LoadingPage";
import Navigation from "../layout/Navigation";
import * as routes from "../../constants/routes";
import SidebarContentAuth from "../dashboard/SidebarContentAuth";
import defaultPhotoURL from "../../assets/images/default_avatar.jpg";
import LocationSearchInput from "../dashboard/LocationSearchInput";
import LocalMap from "../dashboard/LocalMap";
import ReviewsView from "../reviews/ReviewsView";
import { startSetServiceDetails } from "../../actions/service_address";
import ReviewForm from "../reviews/ReviewForm";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

const K_HOVER_DISTANCE = 100 / 2;

class DispensaryDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: {},
      displayName: "",
      city_name: "",
      user_search: null,
      zoomToMarkers: null,
      zoom: null,
      show: false,
      show_modal: false,
      show_modal_sign_up: false,
      center: null,
      latLng: null,
      addressSet: null,
      address: "",
      latLng: {},
      slug: "",
      service_address: [],
    };
    this.handleHideReviewForm = this.handleHideReviewForm.bind(this);
    this.handleShowSignupModal = this.handleShowSignupModal.bind(this);
    this.handleHideSignupModal = this.handleHideSignupModal.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  componentDidMount() {
    this.getLocation();
    this.props.fetchLocalReviews();
    const { uid } = this.props.user;
    this.props.startSetJobs(uid);
  }

  static defaultProps = {
    zoom: 12,
  };

  _onBoundsChange = (center, zoom, bounds, marginBounds) => {
    if (this.props.onBoundsChange) {
      this.props.onBoundsChange({ center, zoom, bounds, marginBounds });
    } else {
      this._onChange(center);
    }
  };

  _onChange({ center, zoom, bounds, ...other }) {
    this.setState({ zoom: zoom });
  }

  onSearchInputChange = (e) => {
    const displayName = e.target.value;
    this.setState(() => ({ displayName }));
  };

  onStateChange = (e, center) => {
    const city_name = e.target.value;
    this.setState(() => ({ city_name, center }));
    this.props.startSetDispensaries(city_name);
  };

  // searchDispensaries = () => {
  //   const cityName = this.state.city_name;
  //   this.props.startSetDispensaries(cityName);
  // };

  handleShow() {
    this.setState({ show_modal: true });
  }

  handleHideReviewForm() {
    this.setState({ show_modal: false });
  }

  handleShowSignupModal() {
    this.setState({ show_modal_sign_up: true });
  }

  handleHideSignupModal() {
    this.setState({ show_modal_sign_up: false });
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        let serviceArr = [];
        serviceArr.push(results[0]);
        this.setState({
          service_address: serviceArr,
        });

        getLatLng(results[0]).then((latLng) => {
          results[0].address = results[0].formatted_address;
          results[0].lat = latLng.lat;
          results[0].lng = latLng.lng;
          results[0].img_url = `https://maps.googleapis.com/maps/api/streetview?size=600x600&location=${latLng.lat},${latLng.lng}&fov=80&heading=-120&pitch=10&key=AIzaSyAXapDoZ0Z_GbKfZGaLbshKJoiH_tmJBAs`;

          let serviceArr = [];
          serviceArr.push(results[0]);

          this.props.startSetServiceDetails(serviceArr);

          this.setState({
            address,
            latLng,
            slug: results[0].place_id,
            center: latLng,
          });

          this.props.fetchReviews(results[0].place_id);

          // this.props.fetchUserProfileProps(results[0].place_id);
          this.setState({ addressSet: true, showAddress: true });
        });
      })
      .catch((error) => console.error("Error", error));
  };

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.setState({ center: pos });
      });
    } else {
      this.setState({ err: "Geolocation is not supported by this browser." });
    }
  }

  star_rating = (rating) => {
    switch (rating) {
      case 1:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#f44336" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
          </span>
        );
      case 2:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#f44336" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#f44336" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
          </span>
        );
      case 3:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#FBC02D" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#FBC02D" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#FBC02D" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
          </span>
        );
      case 4:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#cccccc" }}
            ></i>
          </span>
        );
      case 5:
        return (
          <span>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
            <i
              className="fas fa-star"
              style={{ marginRight: "6px", color: "#388E3C" }}
            ></i>
          </span>
        );
      default:
        return <span> No Rating</span>;
    }
  };

  onMapClick = (e) => {
    console.log("e::: ", e);
    // http://maps.googleapis.com/maps/api/geocode/json?latlng=44.4647452,7.3553838&sensor=true
    // this.setState({
    //   latLng: [
    //     {
    //       lat: e.latLng.lat(),
    //       lng: e.latLng.lng(),
    //     },
    //   ],
    // });
  };

  // onMapLoad = map => {
  //   let marker = new google.maps.Marker({
  //   position: markerPosition,
  //   map: map,
  //   draggable: true,
  // })
  // marker.addListener('dragend', handleDragEnd)
  // }

  // handleDragEnd = e => {
  //     console.log(e.latLng)
  //   }

  // renderMarkers(map, maps) {
  //     let marker = new maps.Marker({
  //       position: this.state.latLng,
  //       map,
  //       title: 'Hello World!'
  //     });
  //   }

  renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: this.state.latLng.lat, lng: this.state.latLng.lng },
      map,
      title: "Hello World!",
    });
    return marker;
  };

  render() {
    const reaviewLocationsArr = _.reverse(this.props.reviews_locations);
    const places = reaviewLocationsArr.map((place) => {
      const { id } = place;
      return (
        <MapSmallCard
          key={id}
          text={place}
          hover={this.props.hoverKey === id}
          lat={place.lat}
          lng={place.lng}
          img_url={`https://maps.googleapis.com/maps/api/streetview?size=440x440&location=${place.lat},${place.lng}&fov=80&heading=-120&pitch=10&key=AIzaSyAXapDoZ0Z_GbKfZGaLbshKJoiH_tmJBAs`}
        />
      );
    });

    const place = this.state.service_address.map((place, index) => {
      return (
        <MapSmallCard
          key={index}
          text={place}
          hover={this.props.hoverKey === index}
          lat={place.lat}
          lng={place.lng}
          img_url={`https://maps.googleapis.com/maps/api/streetview?size=440x440&location=${place.lat},${place.lng}&fov=80&heading=-120&pitch=10&key=AIzaSyAXapDoZ0Z_GbKfZGaLbshKJoiH_tmJBAs`}
        />
      );
    });

    // const street_view_img = `https://maps.googleapis.com/maps/api/streetview?size=800x800&location=${this.state.latLng.lat},${this.state.latLng.lng}&fov=80&heading=-120&pitch=10&key=AIzaSyAXapDoZ0Z_GbKfZGaLbshKJoiH_tmJBAs`;
    return (
      <Layout>
        <Grid fluid style={{ height: "100vh" }}>
          <div>
            <Col md={2} lg={2} smHidden xsHidden style={{ marginTop: 12 }}>
              <SidebarContentAuth />
            </Col>
            <Col md={10} sm={12} xs={12}>
              <Col md={9} className="no-margin">
                <div
                  style={{ height: "100vh", width: "100%", marginBottom: 16 }}
                >
                  <GoogleMapReact
                    // onLoad={this.onMapLoad}
                    bootstrapURLKeys={{
                      key: "AIzaSyAXapDoZ0Z_GbKfZGaLbshKJoiH_tmJBAs",
                    }}
                    defaultCenter={this.state.center}
                    center={this.state.center}
                    zoom={18}
                    onBoundsChange={this._onBoundsChange}
                    hoverDistance={K_HOVER_DISTANCE}
                    onGoogleApiLoaded={({ map, maps }) =>
                      this.renderMarkers(map, maps)
                    }
                    yesIWantToUseGoogleMapApiInternals={true}
                    // onClick={this.onMapClick}
                  >
                    {places}
                    {place}
                  </GoogleMapReact>
                </div>
              </Col>

              <Col md={3} style={{ height: "100%", overflow: "hidden" }}>
                {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 8,
                    }}
                  >
                    <div style={{ width: "80%" }}>
                      <div class="input-group input-group-lg  dropdown-menu-right">
                        <div className="select-container">
                          <select
                            className="select-css"
                            value={this.state.city_name}
                            onChange={this.onStateChange}
                          >
                            {this.state.cities.map((item) => (
                              <option
                                key={item.state_code}
                                value={item.city_name}
                              >
                                {item.city_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div style={{ justifyContent: "flex-end" }}>
                      <h3
                        style={{
                          fontWeight: "bold",
                          fontSize: 14,
                          textTransform: "uppercase",
                          lineHeight: 0.5,
                        }}
                      >
                        {jobsArr.length} Results
                      </h3>
                    </div>
                  </div> */}
                <h1 style={{ fontSize: 38, fontWeight: "bold" }}>
                  Search address
                </h1>

                <div style={{ width: "100%" }} className="search-card-style">
                  <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            overflow: "hidden",
                            borderRadius: 12,
                            marginBottom: 12,
                            paddingLeft: 8,
                            background: "#FFF",
                            alignItems: "center",
                          }}
                        >
                          {this.state.showAddress ? (
                            <></>
                          ) : (
                            <div>
                              <i
                                className="fas fa-search"
                                style={{
                                  marginRight: "6px",
                                  fontSize: 20,
                                  marginRight: 12,
                                }}
                              ></i>
                            </div>
                          )}

                          <input
                            style={{ height: 60, width: "100%" }}
                            {...getInputProps({
                              placeholder: "Search for service address",
                              className: "location-search-input",
                            })}
                          />
                          {this.state.showAddress ? (
                            <div
                              onClick={() => {
                                this.props.fetchReviews("");
                                // this.props.fetchUserProfileProps("");

                                this.setState({
                                  addressSet: false,
                                  showAddress: false,
                                  address: "",
                                });
                              }}
                            >
                              <i
                                className="fas fa-window-close"
                                style={{
                                  marginRight: "6px",
                                  fontSize: 20,
                                  marginRight: 12,
                                }}
                              ></i>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>

                {this.props.service_address.length !== undefined ? (
                  <div className="list-card-style">
                    <div
                      style={{
                        height: "100%",
                        overflow: "hidden",
                        borderTopRightRadius: 22,
                        borderTopLeftRadius: 22,
                      }}
                    >
                      <div>
                        <img
                          src={this.props.service_address[0].img_url}
                          alt="profile img"
                          className="card-img-container"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: 8,
                        marginLeft: 12,
                        marginRight: 12,
                        // flexDirection: "row",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <div style={{ padding: 4 }}>
                          <div className="flex-parent">
                            <p
                              style={{
                                fontSize: 16,
                                fontWeight: "900",
                              }}
                              className="long-and-truncated"
                            >
                              <i className="fas fa-map-marker map-city-icon"></i>
                              {"  "}
                              {this.props.service_address[0].address}
                            </p>
                          </div>
                          <div className="flex-parent">
                            <p
                              className="long-and-truncated"
                              style={{
                                fontSize: 16,
                              }}
                            >
                              <i className="fas fa-bullhorn map-city-icon"></i>
                              {"  "}
                              <strong>{this.props.reviews.length}</strong>{" "}
                              reviews
                            </p>
                          </div>
                          <div>
                            <p className="comment-text">
                              {/* {this.star_rating(profile.rating)} */}
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#FFF",
                            paddingTop: 8,
                            paddingBottom: 8,
                          }}
                        >
                          {this.props.authUser !== null ? (
                            <div>
                              <Button
                                className="btn add-new-post-btn"
                                onClick={this.handleShow}
                                style={{ width: "100%", marginBottom: 8 }}
                              >
                                <p style={{ color: "#FFFFFF", fontSize: 18 }}>
                                  <i
                                    className="fas fa-pencil-alt"
                                    style={{ marginRight: "6px" }}
                                  ></i>
                                  <strong>Write a service review</strong>
                                </p>
                              </Button>
                            </div>
                          ) : (
                            <div>
                              <Button
                                className="btn add-new-post-btn"
                                onClick={this.handleShowSignupModal}
                                style={{ width: "100%", marginBottom: 8 }}
                              >
                                <p style={{ color: "#FFFFFF", fontSize: 18 }}>
                                  <i
                                    className="fas fa-pencil-alt"
                                    style={{ marginRight: "6px" }}
                                  ></i>
                                  <strong>Write a service review</strong>
                                </p>
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div style={{ height: 460, overflow: "scroll" }}>
                        <ReviewsView />
                      </div>
                    </div>
                    <div style={{ height: 100, padding: 12 }}>
                      Are you the current resident to property?
                    </div>
                  </div>
                ) : (
                  <div style={{ width: "100%", height: "100%" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        overflow: "scroll",
                        marginBottom: 80,
                      }}
                    >
                      {reaviewLocationsArr.length === 0 ? (
                        <></>
                      ) : (
                        reaviewLocationsArr.map((profile, index) => {
                          const street_view_img = `https://maps.googleapis.com/maps/api/streetview?size=800x800&location=${profile.lat},${profile.lng}&fov=80&heading=-120&pitch=10&key=AIzaSyAXapDoZ0Z_GbKfZGaLbshKJoiH_tmJBAs`;

                          return (
                            <Col md={12} sm={12} xs={12}>
                              <div
                                onClick={() => {
                                  profile.img_url = street_view_img;
                                  this.props.fetchReviews(profile.place_id);
                                  this.props.startSetServiceDetails([profile]);
                                }}
                              >
                                <div
                                  className="list-card-style"
                                  style={{ marginBottom: 12 }}
                                >
                                  <div
                                    style={{
                                      height: 360,
                                      overflow: "hidden",
                                      borderTopRightRadius: 22,
                                      borderTopLeftRadius: 22,
                                    }}
                                  >
                                    <img
                                      draggable="false"
                                      src={street_view_img}
                                      alt="profile img"
                                      className="card-img-container"
                                    />
                                  </div>

                                  <div
                                    className="list-card"
                                    style={{ flexDirection: "row" }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        paddingLeft: 12,
                                        paddingRight: 12,
                                      }}
                                    >
                                      <div style={{ padding: 4 }}>
                                        <div className="flex-parent">
                                          <p
                                            style={{
                                              fontSize: 14,
                                              fontWeight: "900",
                                            }}
                                            className="long-and-truncated"
                                          >
                                            {profile.displayName}
                                          </p>
                                        </div>
                                        <div className="flex-parent">
                                          <p className="long-and-truncated">
                                            <i className="fas fa-map-marker map-city-icon"></i>{" "}
                                            {profile.address}
                                          </p>
                                        </div>
                                        <div>
                                          <p className="comment-text">
                                            {this.star_rating(profile.rating)}
                                          </p>
                                        </div>
                                        <p>{profile.city}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })
                      )}
                    </div>
                  </div>
                )}
              </Col>
            </Col>
          </div>

          <div>
            <Modal
              {...this.props}
              show={this.state.show_modal_sign_up}
              onHide={this.handleHideSignupModal}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">
                  <div style={{ marginBottom: 24 }}>
                    <a href="/">
                      <img
                        src={logo}
                        style={{ width: 200 }}
                        // className="app-logo-header"
                        alt="critica pro logo"
                      />
                    </a>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ backgroundColor: "#f5f5f7", width: "100%" }}>
                <div style={{ padding: 24 }}>
                  Create an account to access your profile, manage your reviews
                  and explore the possibilitites.
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <div>
                    <Link to={routes.SIGN_UP}>
                      <Button
                        className="btn for-business-btn"
                        style={{
                          width: "100%",
                          marginBottom: 8,
                          padding: 12,
                          paddingRight: 32,
                          paddingLeft: 32,
                        }}
                      >
                        <p style={{ color: "#FFFFFF" }}>Create account</p>
                      </Button>
                    </Link>
                  </div>

                  <div>
                    <Link to={routes.SIGN_IN}>
                      <Button
                        className="btn for-business-btn "
                        style={{
                          width: "100%",
                          marginBottom: 8,
                          padding: 12,
                          paddingRight: 32,
                          paddingLeft: 32,
                        }}
                      >
                        <p style={{ color: "#FFFFFF" }}>Log in</p>
                      </Button>
                    </Link>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>

          <div>
            <Modal
              {...this.props}
              show={this.state.show_modal}
              onHide={this.handleHideReviewForm}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">
                  <h3>{this.state.address} Review</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ backgroundColor: "#f5f5f7", width: "100%" }}>
                <ReviewForm
                  reviewBody={this.props.reviewBody}
                  onSubmit={(reviewBody) => {
                    reviewBody.address = this.props.service_address[0].address;
                    reviewBody.place_id =
                      this.props.service_address[0].place_id;
                    reviewBody.lat = Number(this.props.service_address[0].lat);
                    reviewBody.lng = Number(this.props.service_address[0].lng);
                    this.props.startAddReview(
                      this.props.service_address[0].place_id,
                      reviewBody
                    );
                  }}
                />
              </Modal.Body>
            </Modal>
          </div>
        </Grid>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    service_address: state.service_address,
    user: state.user,
    authUser: state.sessionState.authUser,
    userProps: state.userProps,
    jobs: itemSelector(state.jobs, state.filtersText),
    reviews: state.reviews,
    reviews_locations: state.reviews_locations,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  startSetJobs: (data) => dispatch(startSetJobs(data)),
  startAddReview: (id, reviewBody) => dispatch(startAddReview(id, reviewBody)),
  startSetServiceDetails: (data) => dispatch(startSetServiceDetails(data)),
  fetchLocalReviews: (data) => dispatch(fetchLocalReviews(data)),
  fetchReviews: (data) => dispatch(fetchReviews(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DispensaryDashboard
);
