import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import LocalMap from "./LocalMap";
import { fetchReviews, startAddReview } from "../../actions/reviews";
import ReviewsView from "../reviews/ReviewsView";
import ReviewForm from "../reviews/ReviewForm";
import { Button, Modal } from "react-bootstrap";

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      latLng: {},
      addressSet: false,
      show: false,
      slug: null,
    };
    this.handleHideReviewForm = this.handleHideReviewForm.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleHideReviewForm() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  render() {
    return (
      <div style={{ flex: 1 }}>
        <LocalMap serviceObj={this.state.latLng} />
        {this.state.address !== "" ? (
          <h1>
            <i className="fas fa-home" style={{ marginRight: "6px" }}></i>
            {this.state.address}
          </h1>
        ) : (
          <></>
        )}

        {this.state.addressSet === true ? (
          <div>
            <Button
              className="btn-lg add-new-post-btn"
              onClick={this.handleShow}
              style={{ width: "100%", marginBottom: 8 }}
            >
              <p style={{ color: "#FFFFFF" }}>
                <i
                  className="fas fa-pencil-alt"
                  style={{ marginRight: "6px" }}
                ></i>
                <strong>Write a review</strong>
              </p>
            </Button>
          </div>
        ) : (
          <></>
        )}

        <div>
          <Modal
            {...this.props}
            show={this.state.show}
            onHide={this.handleHideReviewForm}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-lg">
                <h3>{this.state.address} Review</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ backgroundColor: "#f5f5f7", width: "100%" }}>
              <ReviewForm
                reviewBody={this.props.reviewBody}
                onSubmit={(reviewBody) => {
                  this.props.startAddReview(this.state.slug, reviewBody);
                  // this.props.fetchReviews(this.state.slug);
                }}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  authUser: state.sessionState.authUser,
  filters: state.filters.items,
});

const mapDispatchToProps = (dispatch, props) => ({
  startAddReview: (id, reviewBody) => dispatch(startAddReview(id, reviewBody)),
  // fetchProducts: (filters, sort, products) => dispatch(fetchProducts(filters, sort, products)),
  fetchReviews: (data) => dispatch(fetchReviews(data)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  LocationSearchInput
);
