import database from "../firebase/firebase";

export const SET_DISPENSARIES = "SET_DISPENSARIES";

export const setDispensaries = (dispensaries) => ({
  type: SET_DISPENSARIES,
  dispensaries,
});

export const startSetDispensaries = (cityName) => {
  return (dispatch) => {
    let disRef = database.ref(`dispensaries`);

    disRef
      .orderByChild("city")
      .equalTo(cityName)
      .once("value")
      .then((snapshot) => {
        const dispensaries = [];

        snapshot.forEach((childSnapshot) => {
          dispensaries.push({
            id: childSnapshot.key,
            ...childSnapshot.val(),
          });
        });

        // console.log(dispensaries, "JOBS ACTION COMPLEX")
        dispatch(setDispensaries(dispensaries));
      });
  };
};
